import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  useMediaQuery,
} from "@mui/material"
import { forwardRef } from "react"
import { useTheme, Theme } from "@mui/material/styles"
import { dialogConfig } from "../../config/dialogConfig"

interface LmsExportDialogProps {
  courseTitle: string
  url: string
  open: boolean
  showLmsDeleteWarning: boolean
  onClose: () => void
}
/**
 * A modal presenting the scorm URL for a course exported to Skilljar
 *
 * @param props The props
 * @param props.courseTitle Title of course
 * @param props.url  - link to skilljar artifact
 * @param props.open - dialog state
 * @param props.onClose - callback on close
 * @param props.showLmsDeleteWarning - show Delete Warning

 */
const LmsExportDialog = ({
  courseTitle,
  url,
  showLmsDeleteWarning,
  open,
  onClose,
}: LmsExportDialogProps) => {
  const fullScreen = useFullscreenDialog()

  return (
    <Dialog
      {...dialogConfig}
      fullScreen={fullScreen}
      scroll={fullScreen ? "paper" : "body"}
      open={open}
      onClose={onClose}
      sx={{
        ".MuiDialog-paperFullScreen": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle>Exported to Skilljar</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your course "{courseTitle}" has been exported to Skilljar.
        </DialogContentText>

        {showLmsDeleteWarning && (
          <DialogContentText sx={{ mt: 1, mb: 1 }}>
            Some lessons have been removed from the course. This must be
            completed manually from the Skilljar dashboard.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "right" }}>
        <Button fullWidth href={url} target="_blank" rel="noopener noreferrer">
          View in Skilljar
        </Button>
        <Button variant="contained" onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

LmsExportDialog.defaultProps = {
  open: false,
}

export default LmsExportDialog

/**
 * Show as full-screen on sm displays
 */
const useFullscreenDialog = () => {
  const theme: Theme = useTheme()
  return useMediaQuery(theme.breakpoints.down("sm"))
}

const Transition = forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

import { htmlToElement, removeDivWrapper, scrollEditorTo } from "./domUtils"
import { v4 as uuidv4 } from "uuid"
import { removeTemporaryAttributes } from "../custom-elements/CustomElements"

/**
 * Smart Templates. See Smart Templates.md for more information.
 *
 * Responsible for adding and updating smart templates.
 *
 * Controls are inserted as divs into the course HTML. This provides methods to
 * add and remove the controls. Also provides helpers to skip the controls when
 * navigating the course DOM.
 *
 * Main functions:
 *
 * addSmartTemplate - Add a new smart template to a section
 * cleanupSmartTemplateControls - Add controls to all existing smart templates
 * stripTemporaryElements - Remove smart template controls (Called before saving course HTML)
 *
 * DOM Navigation: (use to skip smart template controls)
 *
 * isSmartTemplateControl
 *
 * Other useful functions:
 *
 * findTemplateTypesInSection -  Used by UI to decide whether to show "Add template", or "Update template" menu items
 */

/**
 * Gets all root elements of section, excluding the heading
 *
 * @param {Element} sectionHeading Heading of section
 */
export function getSectionElements(sectionHeading: HTMLElement): HTMLElement[] {
  const elements: HTMLElement[] = []

  let elem = sectionHeading.nextElementSibling
  while (!isContentSectionEnd(sectionHeading, elem)) {
    elements.push(elem as HTMLElement)
    elem = elem!.nextElementSibling
  }

  return elements
}

/**
 * Returns names of template types that are already in a section
 *
 * @param {Element} headingElement Heading element of content section
 * @returns {string[]}
 */
export function findTemplateTypesInSection(
  headingElement: HTMLElement
): string[] {
  if (headingElement == null) {
    return []
  }

  // Search for smart template headings in later siblings
  const templateTypes = []
  for (const elem of getSectionElements(headingElement)) {
    // Exclude if not header of next level
    if (elem.tagName !== `H${parseInt(headingElement.tagName[1]) + 1}`) {
      continue
    }

    const templateType = elem.getAttribute("data-template")
    if (templateType != null) {
      templateTypes.push(templateType)
    }
  }

  return templateTypes
}

/**
 * Remove injected UI elements: loading skeletons, smart template UI.
 * Note: the HTML returned is *not* canonical html. The server must
 * handle non-canonical html that this produces.
 *
 * @param {string} html - HTML of document which may contain loading tags
 * @returns {string} - HTML of entire document without any elements with a Loading tag
 */
export function stripTemporaryElements(html: string): string {
  const tempElement = document.createElement("body")
  tempElement.innerHTML = removeDivWrapper(html)

  removeSmartTemplateProgress(tempElement)
  removeSmartTemplateControls(tempElement)
  removeTemporaryAttributes(tempElement)

  return tempElement.innerHTML
}

/**
 * Remove progress classes and elements from section
 *
 * @param controlElement smart template control element
 */
function removeSmartTemplateProgress(controlElement: HTMLElement) {
  controlElement.classList.remove("updating-text")

  controlElement
    .querySelectorAll(".updating-text")
    .forEach((progressElement) =>
      progressElement.classList.remove("updating-text")
    )

  controlElement
    .querySelectorAll(".smart-template-loading-control")
    .forEach((loadingElement) => loadingElement.remove())

  // Clean up empty class attrs which could be left after removing progress classes
  controlElement.querySelectorAll(`[class=""]`).forEach((elem) => {
    elem.removeAttribute("class")
  })
}

/**
 * Remove UI for all smart templates
 * @param topElement top level element to remove smart template controls from
 */
function removeSmartTemplateControls(topElement: HTMLElement) {
  topElement
    .querySelectorAll("[data-smart-template-control]")
    .forEach((control) => control.remove())
}

/** Gets the content section heading for a smart template heading (i.e. if a
 * smart template is H3, then finds previous H2 or H2)
 * @param smartTemplateHeading heading of smart template
 * @returns content section heading or null
 */
export function getContentSectionHeading(
  smartTemplateHeading: HTMLElement
): HTMLElement | null {
  // Determine containing content section (previous hN where N < heading level)
  const headingLevel = parseInt(smartTemplateHeading.tagName[1])
  let sectionHeading = smartTemplateHeading.previousElementSibling
  while (
    sectionHeading != null &&
    (!sectionHeading.tagName.match(/^H[1-6]$/) ||
      parseInt(sectionHeading.tagName[1]) >= headingLevel)
  ) {
    sectionHeading = sectionHeading.previousElementSibling
  }

  return sectionHeading as HTMLElement | null
}

/**
 * Check if an element is a content section end for a
 * given start element
 *
 * @param {Element} headingElement heading element of content section
 * @param possibleEndElement element that is later sibling of startElement
 */
function isContentSectionEnd(
  headingElement: Element,
  possibleEndElement: Element | null
): boolean {
  if (possibleEndElement == null) {
    // At end of doc - counts as section end
    return true
  } else if (
    ["H1", "H2", "H3", "H4", "H5"].includes(possibleEndElement.tagName)
  ) {
    // Same or lower level heading is start of next section
    const startLevel = parseInt(headingElement.tagName.slice(1))
    const endLevel = parseInt(possibleEndElement.tagName.slice(1))
    return endLevel <= startLevel
  }

  return false
}

/**
 * Check if provided element is blank (ie just a carriage return or non breaking space)
 * @param element - Element that is possibily blank
 */
function isEmptyElement(element: HTMLElement): boolean {
  // Strip all "empty" characters from HTML contents
  const text = element.textContent
  if (text) {
    const trimmedHTML = text.trim().replace(/[\u200B-\u200D\uFEFF]|\s/g, "") // Combined regex for replacing ZeroWidthCharacter and whitespace
    return trimmedHTML === ""
  }

  return true
}

/**
 * Add a new template to a section
 *
 * @param editor Froala editor
 * @param templateType e.g. "demonstration"
 * @param sectionHeading DOM element of heading of section within which to add template
 */
export async function addSmartTemplate(
  editor: any,
  templateType: string,
  sectionHeading: HTMLElement
): Promise<void> {
  // Create id for new section
  const newSectionId = uuidv4()

  // Get next header level
  const nextHeaderLevel = parseInt(sectionHeading.tagName.slice(1)) + 1

  // Create html of new section
  let newSectionHtml: string
  if (templateType === "section") {
    newSectionHtml = `<h2 id="${newSectionId}">SECTION TITLE PLACEHOLDER</h2>
    <h3 id="${uuidv4()}" data-template="demonstration">Demonstration</h3>
    <p></p>
    <h3 id="${uuidv4()}" data-template="exercise">Exercise</h3>
    <p></p>
    <h3 id="${uuidv4()}" data-template="test_question">Test Questions</h3>
    <p></p>
    `
  } else {
    let sectionTitle: string
    if (templateType === "demonstration") {
      sectionTitle = "Demonstration"
    } else if (templateType === "exercise") {
      sectionTitle = "Exercise"
    } else if (templateType === "test_question") {
      sectionTitle = "Test Questions"
    } else if (templateType === "learning_objectives") {
      sectionTitle = "Learning Objectives"
    } else {
      throw new Error("Unknown template type")
    }

    // Determine whether to open the panel immediately
    const openPanelImmediately = templateType !== "test_question"

    // Create new section html with the new section id and the appropriate template type.
    // Set to update immediately so that the template is generated immediately.
    newSectionHtml = `<h${nextHeaderLevel} id="${newSectionId}" data-template="${templateType}">${sectionTitle}</h${nextHeaderLevel}>
    <div data-smart-template-control="${templateType}" data-open-panel-immediately="${
      openPanelImmediately ? "true" : "false"
    }"></div>
    <p></p>`
  }

  // Find elements belonging the section
  const sectionElements = getSectionElements(sectionHeading)

  /** Insert the new section at the end of the section. */
  function insertAtEnd() {
    // Find elements belonging to section
    if (sectionElements.length > 0) {
      sectionElements[sectionElements.length - 1].insertAdjacentHTML(
        "afterend",
        newSectionHtml
      )
    } else {
      // If no end node, we're at the end of the document. Simply insert the section at the end.
      const doc = document.getElementsByClassName("fr-element")[0]
      doc.insertAdjacentHTML("beforeend", newSectionHtml)
    }
  }

  // If the template is a learning objective, insert the section prior to the other content.
  if (templateType === "learning_objectives") {
    // Find first element in the section that is of the same level as the new section.
    const firstElement = sectionElements.find(
      (element) => element.tagName === `H${nextHeaderLevel}`
    )

    // Insert before the first element of the same level, or at the end of the section.
    if (firstElement) {
      firstElement.insertAdjacentHTML("beforebegin", newSectionHtml)
    } else {
      insertAtEnd()
    }
  } else if (templateType === "demonstration") {
    // If the template is a demonstration, insert the section before the first exercise or test question.
    const firstExerciseOrTestQuestion = sectionElements.find(
      (element) =>
        element.dataset.template === "exercise" ||
        element.dataset.template === "test_question"
    )

    if (firstExerciseOrTestQuestion) {
      firstExerciseOrTestQuestion.insertAdjacentHTML(
        "beforebegin",
        newSectionHtml
      )
    } else {
      insertAtEnd()
    }
  } else if (templateType === "exercise") {
    // If the template is an exercise, insert the section before the first test question.
    const firstTestQuestion = sectionElements.find(
      (element) => element.dataset.template === "test_question"
    )

    if (firstTestQuestion) {
      firstTestQuestion.insertAdjacentHTML("beforebegin", newSectionHtml)
    } else {
      insertAtEnd()
    }
  } else {
    insertAtEnd()
  }

  // Add undo step
  editor.undo.saveStep()

  // Get newly inserted element (requires the DOM to settle for unknown reasons)
  setTimeout(() => {
    const element = document.getElementById(newSectionId)
    scrollEditorTo(element)
  }, 0)
}

/**
 * Cleanup UI for all smart templates in the container, adding controls
 * that don't exist and removing stray controls as appropriate
 * @param container container of editor
 */
export function cleanupSmartTemplateControls(container: HTMLElement) {
  // First remove template for any H1s as they cannot be smart templates
  for (const h1 of container.querySelectorAll("H1")) {
    h1.removeAttribute("data-template")
  }

  // Add controls to all smart templates
  const smartTemplateHeadings = [...container.children].filter(
    // Find all headings that are smart templates. H2s are always smart templates
    (child) =>
      child instanceof HTMLElement &&
      (child.dataset.template != null || child.tagName === "H2") &&
      child.tagName.match(/^H[1-6]$/) &&
      !isEmptyElement(child)
  ) as HTMLElement[]
  smartTemplateHeadings.forEach((templateHeading) => {
    addSmartTemplateControlBelowHeading(templateHeading)
  })

  // Remove stray controls. On paste, class is removed, so find data-smart-template-control too
  for (const control of container.querySelectorAll(
    ".smart-template-control,[data-smart-template-control]"
  )) {
    if (
      !smartTemplateHeadings.includes(
        control.previousElementSibling as HTMLElement
      )
    ) {
      control.remove()
    }
  }
}

/**
 * Add UI for a smart template below a smart template heading. If one already exists,
 * do nothing.
 *
 * @param smartTemplateHeading heading of smart template section
 *
 */
function addSmartTemplateControlBelowHeading(
  smartTemplateHeading: HTMLElement
) {
  if (smartTemplateHeading != null) {
    const templateType =
      smartTemplateHeading.getAttribute("data-template") || "rewrite"

    const existingControl = smartTemplateHeading.nextElementSibling
    if (
      existingControl &&
      existingControl instanceof HTMLElement &&
      existingControl.dataset.smartTemplateControl
    ) {
      // Already exists. Ensure it has the right template type and template id
      if (existingControl.dataset.smartTemplateControl !== templateType) {
        existingControl.dataset.smartTemplateControl = templateType
      }
      if (existingControl.dataset.smartTemplateId !== smartTemplateHeading.id) {
        existingControl.dataset.smartTemplateId = smartTemplateHeading.id
      }
      return
    }

    const templateId = smartTemplateHeading.getAttribute("id")!

    const smartTemplateControl = makeSmartTemplateControl(
      templateId,
      templateType
    )

    if (smartTemplateControl != null) {
      smartTemplateHeading.insertAdjacentElement(
        "afterend",
        smartTemplateControl
      )
    }
  }
}

/**
 * Return true if the element is a smart template control
 *
 * @param element element to check
 * @returns true if the element is a smart template control
 */
export function isSmartTemplateControl(element: Element | null): boolean {
  return (
    (element != null &&
      element instanceof HTMLElement &&
      element.dataset.smartTemplateControl != null) ||
    element?.classList?.contains("smart-template-loading-control") === true
  )
}

/**
 * Create UI for a smartTemplate control
 *
 * returns a div with heading, template text, concepts, and update section
 *
 * @param templateId id of smart template heading
 * @param smartTemplateType type of smart template e.g. "demonstration"
 *
 */
function makeSmartTemplateControl(
  templateId: string,
  smartTemplateType: string
): HTMLElement {
  // Add data-smart-template-control attribute so that it can be removed after a cut/paste
  // as Froala strips classes from pasted elements
  const control = `<div data-smart-template-control="${smartTemplateType}" data-smart-template-id="${templateId}"></div>`
  return htmlToElement(control) as HTMLElement
}

/** Find the owning section header node for the start element
 * @param root root element to stop at
 * @param startElement element to find owning section header for
 * */
export function findOwningSectionHeaderElement(
  root: HTMLElement,
  startElement: HTMLElement
) {
  let node: Element | null = startElement

  // Walk up (previousSibling or parent if none) until we find a header node
  // that isn't a smart template
  while (node) {
    if (node === root) {
      return null
    }
    if (
      node.nodeType === Node.ELEMENT_NODE &&
      ["H1", "H2", "H3", "H4", "H5"].includes(node.nodeName) &&
      node.getAttribute("data-template") == null
    ) {
      return node as HTMLElement
    }
    node = node.previousElementSibling || node.parentElement
  }
  return null
}

/** Allow tests for non-exported methods */
export const smartTemplateTestExports = {
  addSmartTemplateControlBelowHeading,
  makeSmartTemplateControl,
}

import exportMicroLearning from "../api/exportMicroLearning"
import waitForJob from "./waitForJob"
import { microlearningAsyncStatusEnum } from "../utils/constants"
import * as api from "../../../api"
import { ExportLocation } from "../../export/ExportTypes"
import { ExportTargetName } from "../../export/utils/contants"
import {
  GoogleDriveUploadResult,
  uploadUrlToGoogleDrive,
} from "../../export/api/uploadToGoogleDrive"
import { makeMicroLearningExportFilename } from "../../../utilities/exportUtils"

/**
 * Start the download for the generated microlearning file
 *
 * Note that this download requires signed cookies
 * for AWS Cloudfront origin authentication
 * (which are updated in the api call)
 *
 * @param {string} url - the file URL
 * @param {string} fileName - the file name
 */
const downloadURL = async (url: string, fileName: string) => {
  await api.downloadFile(url, fileName)
}

/**
 * Submit the async job to build the files for microlearning and return a URl
 * for download
 * @param data - All options for how the microlearning is assembled
 * @param target - Target for export
 * @param location - Google drive loacation
 */
const submitMicroLearningExport = async (
  data: any,
  target: ExportTargetName,
  location?: ExportLocation
): Promise<GoogleDriveUploadResult | null> => {
  const jobId = await exportMicroLearning(data).then(
    ({ data }) => data.async_id
  )

  const microLearningResponse: any = await waitForJob(jobId, {
    completeStatus: microlearningAsyncStatusEnum.COMPLETE,
    failedStatus: microlearningAsyncStatusEnum.FAILED_NO_RETRY,
  })

  const { export_url } = microLearningResponse

  // The ML service makes a name out of the options
  const baseFilename = export_url.split("/").pop()

  const fileName = makeMicroLearningExportFilename(
    baseFilename,
    location?.saveTo
  )

  if (target === "google-drive" && location != null) {
    return uploadUrlToGoogleDrive(
      export_url,
      location.folderId,
      location.folderName,
      fileName,
      location.saveTo
    )
  } else {
    await downloadURL(export_url, fileName)
  }

  return null
}

export default submitMicroLearningExport

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material"
import useInsertVideoURL from "./hooks/useInsertVideoURL"
import { useMixpanelVideoTracker } from "../../utilities/mixpanel"
import useCourseId from "../../hooks/useCourseId"
import { dialogConfig } from "../../config/dialogConfig"

/**
 * Create dialog to insert a video
 * @param onClose - Callback to close dialog
 */
export function createInsertVideoDialog(
  /** Called when the dialog is closed with url or null if cancelled */
  onClose: (url: string | null) => void
) {
  return <InsertVideoDialog onInsert={onClose} onCancel={() => onClose(null)} />
}

/** Create dialog to insert a video */
function InsertVideoDialog(props: {
  /** Called when the dialog is cancelled */
  onCancel: () => void

  /** Called when the dialog is saved
   * @param url The url of the video if applicable
   * @param embed The embed code if applicable
   */
  onInsert: (url: string | null) => void
}) {
  const { onCancel, onInsert } = props

  const { url, setUrl, invalidMessage } = useInsertVideoURL()

  const insertDisabled = invalidMessage !== null || url === ""

  const trackVideo = useMixpanelVideoTracker()

  const courseId = useCourseId()

  return (
    <Dialog {...dialogConfig} open={true} fullWidth>
      <DialogTitle>Insert Video</DialogTitle>
      <DialogContent>
        <Box>
          <TextField
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            margin="dense"
            label="Paste in a video URL"
            variant="outlined"
            fullWidth
            value={url}
            onChange={(e) => {
              setUrl(e.target.value)
            }}
          />
        </Box>
        {invalidMessage && <Alert severity="error">{invalidMessage}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={insertDisabled}
          color="primary"
          variant="contained"
          onClick={() => {
            // remove quotes and whitespace from input
            const cleanedURL = url.trim().replace(/['"\s]/g, "")
            onInsert(cleanedURL)
            trackVideo(cleanedURL, courseId || "noCourseID")
          }}
        >
          Insert
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import { useState } from "react"
import useGoogleDriveUploadPicker from "./useGoogleDriveUploadPicker"
import { ExportLocation } from "../ExportTypes"

/**
 * Handle the prompt, OAuth, select folder process
 * @param onGoogleFolderSelected callback when a folder is selected
 */
const useExportToGoogleDrive = (
  onGoogleFolderSelected: (location: ExportLocation) => Promise<void>
) => {
  const [
    googleDriveAccessPromptDialogOpen,
    setGoogleDriveAccessPromptDialogOpen,
  ] = useState(false)

  const { openDrivePicker, onAccessPermitted } = useGoogleDriveUploadPicker({
    onGoogleFolderSelected,
    setGoogleDriveAccessPromptDialogOpen,
  })

  /** Close the prompt dialog */
  const handleCloseGoogleDriveAccessPromptDialog = () => {
    setGoogleDriveAccessPromptDialogOpen(false)
  }

  /** OK from the prompt dialog - continue OAuth process */
  const handleOkGoogleDriveAccessPromptDialog = () => {
    setGoogleDriveAccessPromptDialogOpen(false)
    onAccessPermitted()
  }

  return {
    handleGoogleDriveExport: openDrivePicker,
    googleDriveAccessPromptDialogOpen,
    handleCloseGoogleDriveAccessPromptDialog,
    handleOkGoogleDriveAccessPromptDialog,
  }
}

export default useExportToGoogleDrive

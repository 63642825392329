import { Item, ItemsState } from "../types"

/**
 * Retrieve all entities for a given folder in order
 * @param state - Current state
 * @param folder - Folder to retrieve entities for
 * @param uploadsInProgress - Any pending uploads that should appear as in progress in the list
 */
const selectItemsForFolder = (
  state: ItemsState,
  folder: string,
  uploadsInProgress: any[] = []
) => {
  const { ids, entities } = state

  const list = ids[folder] ?? []

  // Map in-progress uploads to a list that is prepended to the list of items
  const inProgressItems = uploadsInProgress
    .filter((upload) => {
      const itemFolder = upload.data.folder ?? "__ALL__"
      return itemFolder === folder
    })
    .map((upload) => ({
      id: upload.async_id,
      title: upload.data.title,
      isLoading: true,
    }))

  return [...inProgressItems, ...list.map((id) => entities[id])] as Item[]
}

export default selectItemsForFolder

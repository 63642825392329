import { ItemsState } from "../types"
import { keys } from "lodash"

/**
 * Modify the current items in the state to include any recently completed upload jobs
 * @param prev - Current items from state
 * @param uploads - List of newly completed uploads
 */
const addVirtualCompletedUploads = (prev: ItemsState, uploads: any[]) => {
  // Map the completed uploads list to a map of entities with ID as key
  const completedUploadEntities = uploads.reduce((acc, upload) => {
    acc[upload.data.course_id] = {
      title: upload.data.title,
      id: upload.data.course_id,
      isLoading: false,
      status: "Completed",
      last_update: upload.data.last_update,
      duration_minutes: upload.data.duration_minutes,
      folder: upload.data.folder,
    }
    return acc
  }, {})

  const newIDs = { ...prev.ids }

  keys(completedUploadEntities).forEach((id) => {
    const entity = completedUploadEntities[id]
    const folder = entity.folder ?? "__ALL__"

    if (!newIDs[folder]) {
      newIDs[folder] = []
    }

    if (newIDs[folder].includes(entity.id)) {
      return
    }
    newIDs[folder] = [entity.id, ...newIDs[folder]]
  })

  return {
    ...prev,
    ids: newIDs,
    entities: {
      // Add completed uploads to entities map
      ...prev.entities,
      ...completedUploadEntities,
    },
  }
}

export default addVirtualCompletedUploads

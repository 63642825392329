import { Close } from "@mui/icons-material"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  useMediaQuery,
} from "@mui/material"
import { forwardRef } from "react"
import { useTheme, Theme } from "@mui/material/styles"
import { dialogConfig } from "../../config/dialogConfig"

const messages = {
  download:
    "Granting access to Google Drive will permit LEAi to list and download files from your Google Drive.",
  upload:
    "Granting access to Google Drive will permit LEAi to list folders and upload files to your Google Drive.",
}

interface GoogleDriveAccessDialogProps {
  open: boolean
  onClose: () => void
  onOk: () => void
  upload?: boolean
}
/**
 * A modal presenting the scorm URL for a course exported to Skilljar
 *
 * @param props The props
 * @param props.open - dialog state
 * @param props.onClose - callback on close
 * @param props.onOk - callback on ok
 * @param props.upload - upload or download message
 */
const GoogleDriveAccessDialog = ({
  open,
  onClose,
  onOk,
  upload = false,
}: GoogleDriveAccessDialogProps) => {
  const fullScreen = useFullscreenDialog()

  const message = upload ? messages.upload : messages.download
  return (
    <Dialog
      {...dialogConfig}
      fullScreen={fullScreen}
      scroll={fullScreen ? "paper" : "body"}
      open={open}
      onClose={onClose}
      sx={{
        ".MuiDialog-paperFullScreen": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle>
        Allow LEAi to access your Google Drive files?
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 1 }}>{message}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "right" }}>
        <Button onClick={onClose}>No Thanks</Button>
        <Button variant="contained" color="primary" onClick={onOk}>
          Connect to Google Drive
        </Button>
      </DialogActions>
    </Dialog>
  )
}

GoogleDriveAccessDialog.defaultProps = {
  open: false,
}

export default GoogleDriveAccessDialog

/**
 * Show as full-screen on sm displays
 */
const useFullscreenDialog = () => {
  const theme: Theme = useTheme()
  return useMediaQuery(theme.breakpoints.down("sm"))
}

const Transition = forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

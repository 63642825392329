import { HubCapsule } from "@aws-amplify/core"
import { Hub } from "aws-amplify"
import { useLDClient } from "launchdarkly-react-client-sdk"
import { useEffect } from "react"
import { asUserContext, getCurrentUserOrNull } from "./FlagProvider"

/**
 * Configures the flag provider with the current user context.
 */
function useIdentify() {
  const client = useLDClient()
  useEffect(() => {
    if (!client) {
      return
    }
    return onAuthStatusChange(async () => {
      client.identify(asUserContext(await getCurrentUserOrNull()))
    })
  }, [client])
}

export default useIdentify

/**
 * Listen for authentication status changes.
 *
 * @param callback A callback to be invoked on an authentication change.
 * @returns A "unsubscribe" function.
 */
function onAuthStatusChange(callback: () => {}) {
  /**
   * Handles events from the Amplify system.
   *
   * @param option The event package.
   * @param option.payload The event metadata.
   */
  function handleAuthEvent({ payload }: HubCapsule) {
    if (["signIn", "signOut", "cognitoHostedUI"].includes(payload.event)) {
      callback()
    }
  }

  Hub.listen("auth", handleAuthEvent)
  return () => Hub.remove("auth", handleAuthEvent)
}

import PageLayout from "../../components/layouts/PageLayout"
import Button from "@mui/material/Button"
import CourseDropzone from "./CourseDropzone"
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import {
  Box,
  Switch,
  Typography,
  Alert,
  CircularProgress,
  AlertTitle,
  LinearProgress,
} from "@mui/material"
import useCreateCourse from "./hooks/useCreateCourse"
import ErrorMessage from "../../components/widgets/ErrorMessage"
import { Replay } from "@mui/icons-material"
import {
  ButtonEvents,
  useMixpanelButtonTracker,
  useMixpanelFileUploadTracker,
} from "../../utilities/mixpanel"

/**
 * Screen for uploading files, toggling the Apply Template option,
 * and submitting them to begin the course creation process
 * @param props - Component props
 */
const CreateCourseScreen = (props) => {
  const { learningPath, directory } = props
  const [applyTrainingStructure, setApplyTrainingStructure] = useState(true)
  const history = useHistory()

  const toggleApplyTrainingStructure = (e) => {
    setApplyTrainingStructure(e.target.checked)
  }

  const {
    onStart,
    onAccepted,
    loading,
    error,
    setError,
    fileNames,
    setFileNames,
    uploadItems,
    setUploadItems,
    progressMessage,
    uploadProgress,
    rolloutDirectS3Upload,
    onGetHeadings,
  } = useCreateCourse()

  // Prevent navigating away
  useEffect(() => {
    if (!loading) return

    /** Prevent navigating away
     * @param e event object
     */
    const handleBeforeUnload = (e) => {
      e.preventDefault()
      e.returnValue = ""
    }
    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [loading])

  const trackButtonClick = useMixpanelButtonTracker(ButtonEvents.CreateCourse)
  const trackFileUpload = useMixpanelFileUploadTracker()

  return (
    <PageLayout fixed maxWidth="md">
      <Typography gutterBottom variant="h4">
        Create New Course
      </Typography>
      {loading === false && error != null ? (
        <Box
          height="80vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage title={error.title} subtitle={error.subtitle}>
            {error.messages.map((message) => (
              <Typography variant="body1" gutterBottom key={message}>
                {message}
              </Typography>
            ))}
            <Button
              color="primary"
              startIcon={<Replay />}
              onClick={(e) => {
                e.preventDefault()
                setError(null)
              }}
            >
              Retry
            </Button>
          </ErrorMessage>
        </Box>
      ) : (
        <>
          {rolloutDirectS3Upload && loading && (
            <Alert
              severity="info"
              iconMapping={{
                info: <CircularProgress size={18} />,
              }}
            >
              <AlertTitle>
                Your course is being uploaded. Please do not close this page.
              </AlertTitle>
              <Typography sx={{ mb: 2 }} variant="body1">
                {progressMessage}
              </Typography>
              {uploadProgress.map((uploadInfo) => (
                <Box sx={{ mt: 2 }} key={uploadInfo.index}>
                  <LinearProgress
                    variant="determinate"
                    value={uploadInfo.percentCompleted}
                  />
                  <Typography variant="body1" gutterBottom>
                    {uploadInfo.name}
                  </Typography>
                </Box>
              ))}
            </Alert>
          )}
          {(!rolloutDirectS3Upload || !loading) && (
            <>
              <CourseDropzone
                fileNames={fileNames}
                uploadItems={uploadItems}
                setUploadItems={setUploadItems}
                setFileNames={setFileNames}
                setError={setError}
                onAccepted={onAccepted}
                onGetHeadings={(fileInfo, options) =>
                  onGetHeadings(fileInfo, {
                    applyTemplates: applyTrainingStructure,
                    learningPath,
                    ...options,
                  })
                }
              />

              <Box
                display="flex"
                marginTop={4}
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="h6">
                    Let LEAi Structure Your Course
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    LEAi will structure your uploaded content for learning.
                  </Typography>
                </Box>
                <Box display="flex">
                  <Switch
                    data-cy="apply-training-toggle"
                    checked={applyTrainingStructure}
                    onChange={toggleApplyTrainingStructure}
                    inputProps={{ "aria-label": "Apply Training Structure" }}
                    size="large"
                  />
                </Box>
              </Box>
            </>
          )}
          {!applyTrainingStructure && (
            <Alert severity="info">
              LEAi will not make structural changes to your uploaded content{" "}
            </Alert>
          )}
          <Box
            marginTop={4}
            marginBottom={2}
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              sx={{ width: (theme) => theme.spacing(16) }}
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
            <Button
              data-cy="submit-create-course"
              sx={{ width: (theme) => theme.spacing(16) }}
              disabled={loading}
              variant="contained"
              onClick={() => {
                onStart({
                  applyTemplates: applyTrainingStructure,
                  learningPath,
                  directory,
                })
                trackButtonClick()
                trackFileUpload(uploadItems, {
                  applyTrainingStructure,
                  learningPath,
                  directory,
                })
              }}
            >
              Create
            </Button>
          </Box>
          {!rolloutDirectS3Upload && loading && (
            <Alert
              severity="info"
              iconMapping={{
                info: <CircularProgress size={18} />,
              }}
            >
              <AlertTitle>
                Your course is being uploaded. Please do not close this page.
              </AlertTitle>
              {uploadProgress.map((uploadInfo, index) => (
                <Typography key={index} variant="body1" gutterBottom>
                  {uploadInfo.message}
                </Typography>
              ))}
            </Alert>
          )}
        </>
      )}
    </PageLayout>
  )
}

export default CreateCourseScreen

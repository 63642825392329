import React from "react"
import { useLocation } from "react-router-dom"

/**
 * parse the current URL's string into URLSearchParams
 * @returns {URLSearchParams} - represents the parameters
 */
function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export default useQuery

import { Auth } from "@aws-amplify/auth"
import { Box, CircularProgress, Typography } from "@mui/material"
import {
  asyncWithLDProvider,
  LDProvider,
  useLDClient,
} from "launchdarkly-react-client-sdk"
import { ReactNode } from "react"

type FlagProviderProps = {
  // The wrapped react node.
  children: ReactNode
}

// The Launch-Darkly client-side access ID. Defaults to the QA environment key.
const CLIENT_SIDE_ID =
  process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID ?? "645ce67f6ad4e8130f9bcd25"

type User = {
  id: string
  name: string
  groups: string[]
  email: string
  username: string
}

/**
 * Retrieve the user data for the active authentication session. Otherwise null.
 */
export async function getCurrentUserOrNull(): Promise<User | null> {
  try {
    const token = (await Auth.currentSession()).getIdToken().payload

    console.log(token)

    return {
      id: token["sub"],
      email: token["email"],
      name: token["cognito:username"],
      username: token["cognito:username"],
      groups: token["cognito:groups"] ?? [],
    }
  } catch {
    return null
  }
}

/**
 * Converts a given user to an launch darkly identity context.
 * @param user The authenticated user or null.
 */
export function asUserContext(user: User | null) {
  if (!user) {
    return { kind: "user", anonymous: true }
  }

  return {
    kind: "user",
    key: user.id,
    name: user.name,
    username: user.username,
    email: user.email,
    groups: user.groups,
  }
}

/**
 * Creates a provider for the feature flag context.
 *
 * Invoking this async prevents flicker by ensuring the feature flags are
 * available before app render.
 */
export async function asyncWithFlagProvider() {
  const context = asUserContext(await getCurrentUserOrNull())

  return await asyncWithLDProvider({
    clientSideID: CLIENT_SIDE_ID,
    context: context,
    reactOptions: { useCamelCaseFlagKeys: false },
  })
}

/**
 * Provides the current feature configuration context.
 *
 * The provider will listen for any updates to the remote configuration and
 * update the context as changes become available.
 *
 * @param props Props.
 */
function FlagProvider(props: FlagProviderProps) {
  return (
    <LDProvider
      clientSideID={CLIENT_SIDE_ID}
      options={{
        // Cache the latest flag configuration in local-storage to speed up
        // configuration on return visits. There may be some flicker once the
        // latest values are retrieved.
        bootstrap: "localStorage",
      }}
      reactOptions={{ useCamelCaseFlagKeys: false }}
    >
      <LaunchDarklyWrapper>{props.children}</LaunchDarklyWrapper>
    </LDProvider>
  )
}

function LaunchDarklyWrapper({ children }: { children: ReactNode }) {
  const ldClient = useLDClient()

  if (!ldClient) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
        <Typography variant="body1" style={{ marginTop: 16 }}>
          Loading...
        </Typography>
      </Box>
    )
  }

  return <>{children}</>
}

export default FlagProvider

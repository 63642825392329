import { useEffect, useMemo, useState } from "react"
import getDocumentStats, {
  DocumentStats,
} from "../../outline/utils/getDocumentStats"
import * as api from "../../../api"
import { parseQueryString } from "../../../utilities/queryString"
import {
  asyncStatusMessage,
  DEFAULT_PASSING_SCORE,
  DEFAULT_REGISTRATION_CAP,
  exportAsyncStatusEnum,
  LmsTargetName,
  FORMAT_OPTIONS,
  MAX_REGISTRATION_CAP,
  ExportFormat,
  ScormVersion,
  SCORM_VERSION_OPTIONS,
  ExportTargetName,
  OldExportFormat,
} from "../utils/contants"
// @ts-ignore
import { useHistory } from "react-router-dom"
import validateInt from "../utils/ValidateInt"
import useBranding, { IBranding } from "../../branding/hooks/useBranding"
import { makeColorThemeFromColor } from "../../branding/colorTheme"
import removeCourseBrandingOverrides from "../api/removeCourseBrandingOverrides"
import uploadCourseExportImage from "../api/uploadCourseExportImage"
import { useStableCallback } from "../../../hooks/useStableCallback"
import useFormatOptions from "./useFormatOptions"
import useLmsOptions from "./useLmsOptions"
import usePrerequisiteOptions from "./usePrerequisiteOptions"
import { PreviewPowerpointContent } from "../PreviewPowerpointContent"
import { useFlag } from "../../../utilities/feature-management"
import checkForInaccessibleElements, {
  IInaccessibleElements,
} from "../utils/checkForInaccessibleElements"
import { debounce } from "lodash"
import updateLearningManagement from "../api/updateLearningManagement"
import { LmsCourse } from "../../../api/fetchLmsCourse"
import {
  GoogleDriveUploadResult,
  uploadUrlToGoogleDrive,
} from "../api/uploadToGoogleDrive"
import { ExportLocation } from "../ExportTypes"
import { makeExportFilename } from "../../../utilities/exportUtils"

const MAX_NUM_RETRIES = 3 // Number of re-attempts to poll async job status before displaying error
const SAVE_SUMMARY_DEBOUNCE_TIME = 1000 * 2 // 2 second debounce to prevent excessive requests

/**
 * Hook for handling Export screen interactions
 */
const useCourseExport = () => {
  const history = useHistory()

  //TODO: use a form hook to avoid the duplication
  const [courseTitle, setCourseTitle] = useState("")
  const [modalities, setModalities] = useState<string[]>([])
  const [audiences, setAudiences] = useState<string[]>([])
  const [skillLevel, setSkillLevel] = useState("")
  const [highLevelGoal, setHighLevelGoal] = useState("")
  const [prerequisites, setPrerequisites] = useState<string[]>([])
  const [registrationCap, setRegistrationCap] = useState(
    DEFAULT_REGISTRATION_CAP
  )
  const [passingScore, setPassingScore] = useState(DEFAULT_PASSING_SCORE)
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false)
  const [animateListItems, setAnimateListItems] = useState(false)
  const [
    separateDemonstrationsAndExcercises,
    setSeparateDemonstrationsAndExcercises,
  ] = useState(false)

  const [courseHtml, setCourseHtml] = useState("")
  const [courseExportTitle, setCourseExportTitle] = useState("")
  const [restrictNavigation, setRestrictNavigation] = useState(false)
  const [mustPassQuizzes, setMustPassQuizzes] = useState(false)
  const [scormSeparateSections, setScormSeparateSections] = useState(false)
  const [scormExcludeCourseNavigation, setScormExcludeCourseNavigation] =
    useState(false)
  const [scormHideSectionHeadings, setScormHideSectionHeadings] =
    useState(false)
  const [scormEnableImageZoom, setScormEnableImageZoom] = useState(false)

  const [scormDisableAccessibleControls, setScormDisableAccessibleControls] =
    useState(false)

  const [scormHideDuration, setScormHideDuration] = useState(false)

  const [scormExcludeSummarySection, setScormExcludeSummarySection] =
    useState(false)
  const [scormSubSectionNavigation, setScormSubSectionNavigation] =
    useState(false)

  const [excludeExercises, setExcludeExercises] = useState(false)
  const [excludeDemonstrations, setExcludeDemonstrations] = useState(false)
  const [excludeQuizzes, setExcludeQuizzes] = useState(false)
  const [addSlideNumbers, setAddSlideNumbers] = useState(false)

  const [scrollAnimation, setScrollAnimation] = useState(false)
  const [startPageGraphic, setStartPageGraphic] = useState<string | null>(null)
  const [startPageGraphicFile, setStartPageGraphicFile] = useState<any>(null)
  const [bookCoverGraphic, setBookCoverGraphic] = useState<string | null>(null)
  const [bookCoverGraphicFile, setBookCoverGraphicFile] = useState<any>(null)

  const [format, setFormat] = useState<ExportFormat | OldExportFormat>(
    FORMAT_OPTIONS[0].value
  )
  const [scormVersion, setScormVersion] = useState<ScormVersion>(
    SCORM_VERSION_OPTIONS[0].value
  )

  const [translationLanguageCode, handleChangeTranslationLanguageCode] =
    useState<string | null>(null)

  const [summaryHtml, setSummaryHtml] = useState<string>("")

  //properties from the course and course description
  const [courseId, setCourseId] = useState("")

  const [showLearnerLink, setShowLearnerLink] = useState(false)
  const [learnerUrl, setLearnerUrl] = useState("")
  const [showLmsExportLink, setShowLmsExportLink] = useState(false)
  const [showLmsDeleteWarning, setShowLmsDeleteWarning] = useState(false)
  const [showGoogleDriveUploadResult, setShowGoogleDriveUploadResult] =
    useState<GoogleDriveUploadResult | null>(null)

  const [lmsExportLink, setLmsExportLink] = useState("")

  const [showLmsReplaceDialog, setShowLmsReplaceDialog] = useState(false)
  const [publishedCourse, setPublishedCourse] = useState<LmsCourse | null>(null)

  const [duration, setDuration] = useState(0)
  const [wordCount, setWordCount] = useState(0)

  // For Video Script ("professional" or "informal")
  const [tone, setTone] = useState<"professional" | "informal">("professional")

  //utility properties
  const [isLoading, setIsLoading] = useState(true)
  const [asyncJobStatus, setAsyncJobStatus] = useState<any>()
  const [asyncJobError, setAsyncJobError] = useState(false)

  // Branding configuration
  const { branding, setBranding, getGlobalBranding, ...brandingProps } =
    useBranding()

  const [overrides, setOverrides] = useState<IBranding>()

  const [inaccessibleElements, setInaccessibleElements] = useState<
    IInaccessibleElements[]
  >([])

  const [noRewriteSlides, setNoRewriteSlides] = useState(false)

  const formatOptionsRequest = useFormatOptions()
  const lmsOptionsRequest = useLmsOptions()
  const prerequisiteOptionsState = usePrerequisiteOptions(courseId)

  interface CourseDescriptionOptions {
    exportFromPreview?: boolean
  }

  /**
   * Get Course Description
   * Produces a specification for the course and assets to be
   * submitted to the export service.
   *
   * Returns
   * {
        modalities: ["Instructor Led"],
        audiences: ["Customer"],
        skill_level:
          "Introductory - Basic level concepts requiring little or no prerequisite knowledge",
        high_level_goal: "high level goal text",
        prerequisites: ["How to build a Sales Leaderboard"],
        duration: 1020,
      }.

   * @param exportTarget - Google drive, local file, or lms
   * @param brandingFileOverrides - Object of file URLs to become part of the branding.changes object sent to the server on export requests.
   * @param startPageGraphicUrl - SCORM export image url
   * @param bookCoverGraphicUrl - epub book cover image url
   * @param options - options
   */
  const getCourseDescription = (
    exportTarget: ExportTargetName,
    brandingFileOverrides: any,
    startPageGraphicUrl: string,
    bookCoverGraphicUrl: string,
    options: CourseDescriptionOptions = {}
  ) => {
    const { exportFromPreview = false } = options

    const preview = exportTarget === "preview"

    const registration_cap = validateInt(
      registrationCap,
      DEFAULT_REGISTRATION_CAP,
      1,
      MAX_REGISTRATION_CAP
    )

    setRegistrationCap(registration_cap)

    const passing_score = validateInt(
      passingScore,
      DEFAULT_PASSING_SCORE,
      0,
      100
    )

    setPassingScore(passing_score)

    const newColorTheme = {
      mainColor: branding.mainColor,
      backgroundColor: branding.backgroundColor,
    }

    const oldColorTheme = branding.colour
      ? makeColorThemeFromColor(branding.colour)
      : {}

    const colorTheme = useNewColorControls ? newColorTheme : oldColorTheme

    let formatParam
    if (exportTarget.startsWith("lms")) {
      formatParam = exportTarget
    } else if (format === "scorm") {
      formatParam = scormVersion
    } else {
      formatParam = format
    }

    return {
      modalities: modalities,
      audiences: audiences,
      skill_level: skillLevel,
      high_level_goal: highLevelGoal,
      prerequisites: prerequisites,
      duration: duration,
      format: formatParam,
      scorm_version: scormVersion,
      export_target: exportTarget,
      registration_cap,
      passing_score,
      show_correct_answer: showCorrectAnswer,
      animate_list_items: animateListItems,
      course_export_title: courseExportTitle,
      restrict_navigation: restrictNavigation,
      must_pass_quizzes: mustPassQuizzes,
      separate_sections: scormSeparateSections,
      exclude_course_navigation: scormExcludeCourseNavigation,
      hide_section_headings: scormHideSectionHeadings,
      scorm_enable_image_zoom: scormEnableImageZoom,
      scorm_disable_accessible_controls: scormDisableAccessibleControls,
      exclude_summary_section: scormExcludeSummarySection,
      scorm_hide_duration: scormHideDuration,

      include_subsection_navigation: scormSubSectionNavigation,
      scroll_animation: scrollAnimation,
      summary_html: summaryHtml,
      start_page_graphic: startPageGraphicUrl,
      book_cover_graphic: bookCoverGraphicUrl,
      separate_demonstrations_and_excercises:
        separateDemonstrationsAndExcercises,
      tone,
      exclude_exercises: excludeExercises,
      exclude_demonstrations: excludeDemonstrations,
      exclude_quizzes: excludeQuizzes,
      add_slide_numbers: addSlideNumbers,
      no_rewrite_slides: noRewriteSlides,
      translation_language_code: translationLanguageCode,

      /**
       * The export endpoint does not accept File objects, so all branding
       * properties containing files are first generated as S3 URLs, and then
       * normalized to the branding object. This allows the export process on
       * the server to retrieve the required files to apply branding.
       *
       * The changes property will appear in the following format:
       {
          heading: {
            name: <File name>,
            url: <S3 URL>
          },
          body: {
            name: <File name>,
            url: <S3 URL>
          },
          logo: <S3 URL>,
          brandMark: <S3 URL>
       }
       */
      branding: {
        ...branding,
        changes: {
          ...branding.changes,
          ...brandingFileOverrides,
        },
      },
      colorTheme,
      preview,
      exportFromPreview,
      useServerlessExport,
      exportTarget,
    }
  }

  useEffect(() => {
    if (brandingProps.loading) {
      return
    }
    /**
     * If any course-level branding overrides were fetched from loading the course,
     * overwrite the initial global branding properties with them.
     */
    setBranding((prev) => ({
      ...prev,
      ...overrides,
      changes: {},
    }))
  }, [brandingProps.loading, overrides, setBranding])

  /**.
   * Initialize the page
   * - get the course Id
   * - get the list of courses for this user
   * - get whether publish is supported
   *
   */
  useEffect(() => {
    /**
     * Set values for export form
     * @param description - course description object
     */
    const initFormData = (description: any) => {
      /* init export description fields */
      if (description.modalities) {
        setModalities(description.modalities)
      }
      if (description.audiences) {
        setAudiences(description.audiences)
      }
      if (description.skill_level) {
        setSkillLevel(description.skill_level)
      }
      if (description.high_level_goal) {
        setHighLevelGoal(description.high_level_goal)
      }
      if (description.prerequisites) {
        setPrerequisites(description.prerequisites)
      }

      if (description.format) {
        if (rolloutExportToGoogleDrive) {
          if (description.format.startsWith("scorm-")) {
            setFormat("scorm")
            setScormVersion(description.format)
          } else if (description.format.startsWith("lms")) {
            setFormat("scorm")
            setScormVersion("scorm-2004.3")
          } else {
            setFormat(description.format)
          }
        } else {
          if (description.format === "scorm") {
            if (description.scormVersion) {
              setFormat(description.scormVersion)
            } else {
              setFormat("scorm-2004.3")
            }
          } else {
            setFormat(description.format)
          }
        }
      }
      if (description.scormVersion) {
        setScormVersion(description.scormVersion)
      }
      if (description.branding_overrides) {
        setOverrides(description.branding_overrides)
      }
    }

    /**
     * Set the duration so we can send it to the export API, which
     * will include it in a exported course description file.
     * Use the saved duration_minutes from the course if it has been saved,
     * otherwise calculate the duration from the document stats.
     *
     * @param documentStats - document stats
     * @param {number | null} savedDuration - saved duration in minutes or null
     */
    const initDuration = (
      documentStats: DocumentStats,
      savedDuration: number | null
    ) => {
      if (savedDuration != null) {
        // Previously we saved this in seconds, need to keep it consistent
        setDuration(savedDuration * 60)
      } else {
        // Calculate the duration, In case the duration_minutes has not yet
        // been saved with the course
        // which could happen if we export an older course
        // directly without going through the editor

        // Note that this is not entirely accurate since images
        // that have a resized width will not have had their
        // height scaled.
        documentStats.duration?.then((duration) => {
          let calculatedDuration = duration.as("seconds")
          //console.log(`estimated document duration: ${calculatedDuration}`)
          setDuration(calculatedDuration)
        })
      }
    }

    /**
     * Init form from loaded course
     * @param course - course json
     */
    const initCourse = (course: any) => {
      if (!course) {
        return
      }

      /* init form fields from course data */

      setCourseTitle(course.title)

      setCourseHtml(course.assembled_html)

      // Get document stats
      const html = document.createElement("html")
      html.innerHTML = course.assembled_html
      const body = html.getElementsByTagName("body")[0]
      const stats = getDocumentStats(body)

      // Update word count
      setWordCount(stats.wordCount)

      // Get the html document to calculate the duration estimate
      initDuration(stats, course.duration_minutes)

      //update the form variables for export if they already exist in the DB
      if (course.description) {
        initFormData(course.description)
      }

      // Check to see if there are inaccessible elements we need to warn the user of
      setInaccessibleElements(
        checkForInaccessibleElements(course.assembled_html)
      )

      if (course.learning_management) {
        if (course.learning_management.registration_cap != null) {
          setRegistrationCap(course.learning_management.registration_cap)
        }
        if (course.learning_management.learner_url != null) {
          setLearnerUrl(course.learning_management.learner_url)
        }
        if (course.learning_management.passing_score != null) {
          setPassingScore(course.learning_management.passing_score)
        }
        if (course.learning_management.show_correct_answer != null) {
          setShowCorrectAnswer(course.learning_management.show_correct_answer)
        }
        if (course.learning_management.animate_list_items != null) {
          setAnimateListItems(course.learning_management.animate_list_items)
        }
        if (course.learning_management.course_export_title != null) {
          setCourseExportTitle(course.learning_management.course_export_title)
        }
        if (course.learning_management.scroll_animation != null) {
          setScrollAnimation(course.learning_management.scroll_animation)
        }
        if (course.learning_management.restrict_navigation != null) {
          setRestrictNavigation(course.learning_management.restrict_navigation)
        }
        if (course.learning_management.must_pass_quizzes != null) {
          setMustPassQuizzes(course.learning_management.must_pass_quizzes)
        }
        if (course.learning_management.separate_sections != null) {
          setScormSeparateSections(course.learning_management.separate_sections)
        }
        if (course.learning_management.exclude_course_navigation != null) {
          setScormExcludeCourseNavigation(
            course.learning_management.exclude_course_navigation
          )
        }
        if (course.learning_management.hide_section_headings != null) {
          setScormHideSectionHeadings(
            course.learning_management.hide_section_headings
          )
        }
        if (
          course.learning_management.scorm_disable_accessible_controls != null
        ) {
          setScormDisableAccessibleControls(
            course.learning_management.scorm_disable_accessible_controls
          )
        }
        if (course.learning_management.scorm_enable_image_zoom != null) {
          setScormEnableImageZoom(
            course.learning_management.scorm_enable_image_zoom
          )
        }
        if (course.learning_management.exclude_summary_section != null) {
          setScormExcludeSummarySection(
            course.learning_management.exclude_summary_section
          )
        }
        if (course.learning_management.include_subsection_navigation != null) {
          setScormSubSectionNavigation(
            course.learning_management.include_subsection_navigation
          )
        }
        if (course.learning_management.exclude_demonstrations != null) {
          setExcludeDemonstrations(
            course.learning_management.exclude_demonstrations
          )
        }
        if (course.learning_management.exclude_exercises != null) {
          setExcludeExercises(course.learning_management.exclude_exercises)
        }
        if (course.learning_management.exclude_quizzes != null) {
          setExcludeQuizzes(course.learning_management.exclude_quizzes)
        }
        if (course.learning_management.add_slide_numbers != null) {
          setAddSlideNumbers(course.learning_management.add_slide_numbers)
        }
        if (course.learning_management.start_page_graphic != null) {
          setStartPageGraphic(course.learning_management.start_page_graphic)
        }
        if (course.learning_management.book_cover_graphic != null) {
          setBookCoverGraphic(course.learning_management.book_cover_graphic)
        }

        if (
          course.learning_management.separate_demonstrations_and_excercises !=
          null
        ) {
          setSeparateDemonstrationsAndExcercises(
            course.learning_management.separate_demonstrations_and_excercises
          )
        }
        if (course.learning_management.summary_html != null) {
          setSummaryHtml(course.learning_management.summary_html)
        }
      }
    }

    /**
     * Load course data
     * @param courseIdFromParam - course to laod
     */
    const load = async (courseIdFromParam: any) => {
      /* get all we need from the api */

      const { data } = await api.loadCourse(courseIdFromParam)
      initCourse(data)

      setIsLoading(false)
    }

    // parse the query string to get the course id
    const parsedQuery = parseQueryString(window.location.search)
    const courseIdFromParam = parsedQuery.course
    if (courseIdFromParam == null) {
      console.log("the course id is missing from the query string")
    } else {
      setCourseId(courseIdFromParam)
      load(courseIdFromParam)
    }
  }, [])

  /**
   * Start the download for the generated export .zip file.
   *
   * Note that this download requires signed cookies
   * for AWS Cloudfront origin authentication
   * (which are updated in the api call)
   *
   * @param url - the export file URL
   * @param filename - name of downloaded file
   */
  const downloadExportURL = async (url: string, filename: string) => {
    //console.log("downloadExportURL:" + url)
    await api.downloadFile(url, filename)
  }

  /**
   * Send a request to store file uploads and generate URLs for them
   * to be accessed later for export branding
   */
  const getOverrideFileURLs = async () => {
    /**
     * If any branding changes have occurred via file upload, get a URL for
     * that file data from a separate request
     */

    const requiresOverrideURL =
      branding.changes["logo"] ||
      branding.changes["brandMark"] ||
      (branding.changes["body"] && branding.changes["body"] instanceof File) ||
      (branding.changes["heading"] &&
        branding.changes["heading"] instanceof File)

    if (!requiresOverrideURL) {
      return {}
    }
    return await api.createBrandingOverrides(branding.changes)
  }

  /**
   * If a new file has been selected, upload it
   * otherwise, return the current url
   */
  const updatePageGraphicFile = async () => {
    if (startPageGraphicFile == null) {
      return startPageGraphic
    } else {
      return uploadCourseExportImage(courseId, startPageGraphicFile.file)
    }
  }

  /**
   * If a new file has been selected, upload it
   * otherwise, return the current url
   */
  const updateBookCoverGraphicFile = async () => {
    if (bookCoverGraphicFile == null) {
      return bookCoverGraphic
    } else {
      return uploadCourseExportImage(
        courseId,
        bookCoverGraphicFile.file,
        "book-cover"
      )
    }
  }

  interface AsyncJobOptions {
    replaceCourse?: boolean
    exportLocation?: ExportLocation
  }

  /**
   * Start the async job to generate the export file
   * and return the url where it can be downloaded.
   *
   * This function includes a function to start the async job
   * and a polling function to check the status of the async job.
   *
   * After the initial request, the server returns an async id
   * that can be used to check the status of the job.
   *
   * It triggers a progress meter and shows the user the
   * status of the async job.
   *
   * Once the job is complete, a URL is returned that can be
   * used to download the export.
   * @param exportTarget - export to location
   * @param options - export options

   */
  const submitAsyncJobExport = async (
    exportTarget: ExportTargetName,
    options: AsyncJobOptions = {}
  ) => {
    const { replaceCourse = false, exportLocation = null } = options

    //defines how long to wait between async job checks
    const pollDelayTime = 1000

    // Track how many times a re-poll was attempted to get async job status
    let retryCount = 0

    /**
     * Send a request to start async export task.
     * Returns an async id that is used to track async job progress.
     * @param brandingFileOverrides - Any branding file changes made by user
     * @param startPageGraphicUrl - url of scorm export graphic
     * @param bookCoverGraphicUrl - url of epub cover image
     */
    const startAsyncJobExport = async (
      brandingFileOverrides: any,
      startPageGraphicUrl: string,
      bookCoverGraphicUrl: string
    ) => {
      const courseDescription = getCourseDescription(
        exportTarget,
        brandingFileOverrides,
        startPageGraphicUrl,
        bookCoverGraphicUrl,
        {
          exportFromPreview: !!isPowerpointPreviewAvailable,
        }
      )

      const { data } = await api.exportCourseAssets(courseId, courseDescription)

      console.log(data)

      if (exportLocation?.saveTo === "slides" && format === "pptx") {
        data.export_params.include_description_files = false
      }

      if (exportLocation?.saveTo === "docs" && format === "docx") {
        data.export_params.include_description_files = false
      }

      if (useServerlessExport) {
        // send request to the export service
        const resp = await api.startExport(data)
        return resp.data.job_id
      } else {
        return data.async_id
      }
    }

    /**
     * Send a request to start async task for publishing to SCORM.
     * @param brandingFileOverrides - Any branding file changes made by user
     * @param startPageGraphicUrl - url of scorm export graphic
     * @param bookCoverGraphicUrl - url of epub cover image
     */
    const startAsyncJobPublish = (
      brandingFileOverrides: any,
      startPageGraphicUrl: string,
      bookCoverGraphicUrl: string
    ) =>
      api
        .publishCourse(
          courseId,
          getCourseDescription(
            exportTarget,
            brandingFileOverrides,
            startPageGraphicUrl,
            bookCoverGraphicUrl
          )
        )
        .then(({ data }) => data.async_id)
        .catch(() => {
          setAsyncJobError(true)
        })

    /**
     * Check the async job for status, completion or failure
     * keep checking until the job finishes.
     *
     * @param {string} async_id Export job id.
     * @param {string} exportFormat Export format
     * @param {boolean} isServerlessExportJob - True if we are tracking a serverless export job
     */
    async function checkAsyncJobExport(
      async_id: any,
      exportFormat: string,
      isServerlessExportJob: boolean
    ) {
      // Attempt to poll for async job and proceed based on its status
      let asyncJob: any = {
        status: exportAsyncStatusEnum.FAILED_NO_RETRY,
        async_id: async_id,
      }
      try {
        if (isServerlessExportJob) {
          const exportJobData = await api.fetchExportStatus(async_id)
          if (exportJobData != null) {
            const exportUrl = exportJobData.export_url
            const packageUrl = exportJobData.package_url

            asyncJob.status = exportJobData.status
            asyncJob.data = {
              format: exportJobData.format,
              export_url: exportUrl,
              exportLocation,
            }

            if (
              asyncJob.status === exportAsyncStatusEnum.COMPLETE &&
              exportUrl != null
            ) {
              if (exportTarget.startsWith("lms")) {
                const publishExportResponse = await api.publishCourse(
                  courseId,
                  {
                    format: exportTarget,
                    exportUrl,
                    scormSeparateSections,
                    useServerlessExport: true,
                    replaceCourse,
                  }
                )

                if (exportTarget === "lms-scormcloud") {
                  // We get a new async job object to track the interaction with scormcloud
                  asyncJob = publishExportResponse.data
                  async_id = asyncJob.async_id
                  isServerlessExportJob = false
                } else {
                  // We get the skilljar URL right away
                  const skillJarData = publishExportResponse.data
                  asyncJob.data.export_url = skillJarData["skilljar_urls"][0]

                  if ("deleted_lesson_urls" in skillJarData) {
                    asyncJob["deletedLessonUrls"] =
                      skillJarData["deleted_lesson_urls"]
                  }
                }
              } else if (
                exportFormat !== "epub" &&
                exportFormat !== "html" &&
                exportFormat !== "video-script"
              ) {
                let zipped_export_url
                if (packageUrl != null) {
                  zipped_export_url = packageUrl
                } else {
                  zipped_export_url = await api.createExportZip(
                    courseId,
                    exportUrl
                  )
                }
                asyncJob.data.export_url = zipped_export_url
              }
            }
          }
        } else {
          const { data: asyncJobData } = await api.checkAsyncJob(async_id)
          asyncJob = asyncJobData
        }

        // We only track consectutive failures, so reset count to 0 upon a successful poll
        retryCount = 0

        // @ts-ignore
        setAsyncJobStatus(asyncStatusMessage[asyncJob.status])

        //check to see if the file export is ready
        //if not keep checking
        if (asyncJob.status === exportAsyncStatusEnum.COMPLETE) {
          let async_data = asyncJob.data

          if (exportTarget.startsWith("lms")) {
            setIsLoading(false)
            if (async_data.export_url != null) {
              console.log("async_data.format", exportTarget)
              if (exportTarget === "lms-scormcloud") {
                setShowLearnerLink(true)
                setLearnerUrl(async_data.export_url)
              } else {
                setShowLmsExportLink(true)
                setLmsExportLink(async_data.export_url)

                const hasDeletedlessons =
                  asyncJob["deletedLessonUrls"] != null &&
                  asyncJob["deletedLessonUrls"].length > 0
                setShowLmsDeleteWarning(hasDeletedlessons)
              }
            }
          } else {
            const exportLocation = async_data.exportLocation

            const fileName = makeExportFilename(
              exportFormat,
              courseId,
              courseTitle,
              exportLocation?.saveTo
            )

            if (exportLocation != null) {
              const uploadResult = await uploadUrlToGoogleDrive(
                async_data.export_url,
                exportLocation.folderId,
                exportLocation.folderName,
                fileName,
                exportLocation.saveTo
              )

              if (uploadResult == null) {
                setAsyncJobError(true)
                setIsLoading(false)
              } else {
                setShowGoogleDriveUploadResult(uploadResult)
              }
            } else {
              setIsLoading(false)
              downloadExportURL(async_data.export_url, fileName)
              //navigate the user back to the page they were on before
              onExit()
            }
          }
        } else if (asyncJob.status === exportAsyncStatusEnum.FAILED_NO_RETRY) {
          setAsyncJobError(true)
          setIsLoading(false)
        } else {
          setTimeout(() => {
            checkAsyncJobExport(
              asyncJob.async_id,
              exportFormat,
              isServerlessExportJob
            )
          }, pollDelayTime)
        }
      } catch (err) {
        /**
         * To guard against requests being dropped intermittently, apply a retry
         * in checking the async job.
         */
        if (retryCount <= MAX_NUM_RETRIES) {
          retryCount++
          console.log(
            "Retrying async job poll. Current retry count: " + retryCount
          )

          // Retry the poll, in case of intermittent outage
          setTimeout(() => {
            checkAsyncJobExport(async_id, exportFormat, isServerlessExportJob)
          }, pollDelayTime)
        } else {
          console.error(err)
          setAsyncJobError(true)
          setIsLoading(false)
        }
      }
    }

    // initiate the request to the async job service
    // and then start polling for updates
    // the first async job check is sent without delay in case
    // processing is already complete.
    try {
      setIsLoading(true)
      let jobID
      const brandingFileOverrides = await getOverrideFileURLs()
      const startPageGraphicUrl = await updatePageGraphicFile()
      const bookCoverGraphicUrl = await updateBookCoverGraphicFile()

      if (format.startsWith("lms") && !useScormServerlessExport) {
        jobID = await startAsyncJobPublish(
          brandingFileOverrides,
          startPageGraphicUrl,
          bookCoverGraphicUrl
        )
      } else {
        jobID = await startAsyncJobExport(
          brandingFileOverrides,
          startPageGraphicUrl,
          bookCoverGraphicUrl
        )
      }
      checkAsyncJobExport(jobID, format, useServerlessExport)
    } catch (error) {
      console.error("error: could not start async job", error)
      setAsyncJobError(true)
      setIsLoading(false)
    }
  }

  /**
   * Handlers for UI interactions.
   * @param e Event.
   */
  const handleChangeModalities = (e: any) => {
    setModalities(e.target.value)
  }
  /**
   * Handlers for UI interactions.
   * @param e Event.
   */
  const handleChangeAudiences = (e: any) => {
    setAudiences(e.target.value)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeSkillLevel = (e: any) => {
    setSkillLevel(e.target.value)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeHighLevelGoal = (e: any) => {
    setHighLevelGoal(e.target.value)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangePrerequisites = (e: any) => {
    setPrerequisites(e.target.value)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeFormat = (e: any) => {
    setFormat(e.target.value)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeScormVersion = (e: any) => {
    setScormVersion(e.target.value)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeTone = (e: any) => {
    setTone(e.target.value)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeRegistrationCap = (e: any) => {
    setRegistrationCap(validateInt(e.target.value, "", 1, MAX_REGISTRATION_CAP))
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangePassingScore = (e: any) => {
    setPassingScore(validateInt(e.target.value, "", 0, 100))
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeShowCorrectAnswer = (e: any) => {
    setShowCorrectAnswer(e.target.checked)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeAnimateListItems = (e: any) => {
    setAnimateListItems(e.target.checked)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeCourseExportTitle = (e: any) => {
    setCourseExportTitle(e.target.value)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeScrollAnimation = (e: any) => {
    setScrollAnimation(e.target.checked)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeRestrictNavigation = (e: any) => {
    setRestrictNavigation(e.target.checked)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeMustPassQuizzes = (e: any) => {
    setMustPassQuizzes(e.target.checked)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeScormExcludeCourseNavigation = (e: any) => {
    setScormExcludeCourseNavigation(e.target.checked)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeScormHideSectionHeadings = (e: any) => {
    setScormHideSectionHeadings(e.target.checked)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeScormEnableImageZoom = (e: any) => {
    setScormEnableImageZoom(e.target.checked)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeScormDisableAccessibleControls = (e: any) => {
    setScormDisableAccessibleControls(e.target.checked)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeScormHideDuration = (e: any) => {
    setScormHideDuration(e.target.checked)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeScormExcludeSummarySection = (e: any) => {
    setScormExcludeSummarySection(e.target.checked)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeScormSubSectionNavigation = (e: any) => {
    setScormSubSectionNavigation(e.target.checked)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeScormSeparateSections = (e: any) => {
    setScormSeparateSections(e.target.checked)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeExcludeDemonstrations = (e: any) => {
    setExcludeDemonstrations(e.target.checked)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeExcludeExercises = (e: any) => {
    setExcludeExercises(e.target.checked)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeExcludeQuizzes = (e: any) => {
    setExcludeQuizzes(e.target.checked)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeAddSlideNumbers = (e: any) => {
    setAddSlideNumbers(e.target.checked)
  }

  // Add this handler alongside other handlers
  const handleChangeNoRewriteSlides = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNoRewriteSlides(event.target.checked)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeStartPageGraphicFile = (
    imageUrl: string | null,
    file: any
  ) => {
    setStartPageGraphic(imageUrl)
    if (imageUrl == null) {
      setStartPageGraphicFile(null)
    } else {
      setStartPageGraphicFile({ imageUrl, file })
    }
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeBookCoverGraphicFile = (
    imageUrl: string | null,
    file: any
  ) => {
    setBookCoverGraphic(imageUrl)
    setBookCoverGraphicFile({ imageUrl, file })
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const handleChangeSeparateDemonstrationsAndExcercises = (e: any) => {
    setSeparateDemonstrationsAndExcercises(e.target.checked)
  }

  /**
   * user initiated course export
   */
  const handleExportAssets = async () => {
    if (format.startsWith("lms")) {
      const lmsName = format as LmsTargetName
      handleExportAssetsToLms(lmsName)
    } else {
      handleExportAssetsToLocal()
    }
  }

  /**
   * user initiated course export
   */
  const handleExportAssetsToLocal = async () => {
    setIsLoading(true)
    submitAsyncJobExport("download")
  }

  /**
   * user initiated course export to lms
   * @param lmsName name of lms
   */
  const handleExportAssetsToLms = async (lmsName: LmsTargetName) => {
    if (!(await promptToReplace(courseId, lmsName))) {
      setIsLoading(true)
      submitAsyncJobExport(lmsName)
    }
  }
  /**
   * user initiated course export to google drive
   * @param exportLocation ExportLocation - place to save export
   */
  const handleExportAssetsToGoogleDrive = async (
    exportLocation: ExportLocation
  ) => {
    setIsLoading(true)
    submitAsyncJobExport("google-drive", {
      exportLocation,
    })
  }

  /**
   * Return true if we're prompting the user to replace
   * a published course
   * @param courseId - the course
   * @param lmsName - name of lms
   */
  const promptToReplace = async (
    courseId: string,
    lmsName: LmsTargetName
  ): Promise<boolean> => {
    if (updateSkilljarCourses && lmsName === "lms-skilljar") {
      const lmsCourse = await api.fetchLmsCourse("lms-skilljar", courseId)

      if (lmsCourse != null) {
        setPublishedCourse(lmsCourse)
        setShowLmsReplaceDialog(true)
        return true
      }
    }

    return false
  }

  /**
   * Handle result of replace published course dialog
   * @param replaceCourse - true if the user chose to replace
   */
  const handleReplaceSkillJar = async (replaceCourse: boolean) => {
    setIsLoading(true)
    submitAsyncJobExport("lms-skilljar", { replaceCourse })
  }

  /**
   * Handle result of preview button
   */
  const handlePreview = async () => {
    setIsLoading(true)
    submitAsyncJobExport("preview")
  }

  /**
   * Send the user to back to the previous page,
   * where they came from originally.
   */
  const onExit = () => {
    history.goBack()
  }

  /**
   * Rollback to original global branding default by removing
   * any course-level branding overrides
   */
  const restoreDefaultBranding = () => {
    // @ts-ignore
    setOverrides({})
    return removeCourseBrandingOverrides(courseId).then(() => {
      getGlobalBranding()
    })
  }

  const rolloutExportToGoogleDrive = useFlag("rollout-export-to-google-drive")

  // Check if Powerpoint preview is available
  const isPowerpointPreviewAvailable = useFlag("rollout-export-pptx-gpt-4")

  // Check serverless export
  const useScormServerlessExport = !!useFlag("rollout-serverless-scorm-export")
  const useDocxServerlessExport = !!useFlag("rollout-serverless-docx-export")
  const useEpubServerlessExport = !!useFlag("rollout-serverless-epub-export")
  const useHtmlServerlessExport = !!useFlag("rollout-serverless-html-export")
  const usePPtxServerlessExport =
    !!useFlag("rollout-serverless-pptx-export") && isPowerpointPreviewAvailable
  const useVideoScriptServerlessExport = !!useFlag(
    "rollout-serverless-video-script-export"
  )

  const updateSkilljarCourses = !!useFlag("rollout-skilljar-course-updating")

  const useNewColorControls = !!useFlag("rollout-branding-new-color-controls")

  const isScorm = format.startsWith("scorm") || format.startsWith("lms")

  const useServerlessExport =
    (isScorm && useScormServerlessExport) ||
    (format === "docx" && useDocxServerlessExport) ||
    (format === "html" && useHtmlServerlessExport) ||
    (format === "pptx" && usePPtxServerlessExport) ||
    (format === "video-script" && useVideoScriptServerlessExport) ||
    (format === "epub" && useEpubServerlessExport)

  // Determine if course is too large for pptx export
  const tooLargeForPptxExport =
    wordCount > 15000 && isPowerpointPreviewAvailable

  const noExportMessage =
    tooLargeForPptxExport && format === "pptx"
      ? "Course is too large to export to PowerPoint. Current limit is 15,000 words."
      : undefined

  // Determine if can preview
  // scorm-cmi5 does not work but we substitute scorm-1.2
  const canPreview =
    !noExportMessage &&
    ((isScorm && !scormSeparateSections) ||
      (isPowerpointPreviewAvailable && format === "pptx"))

  // Determine if can export
  const canExport = !noExportMessage

  /** Gets the preview course description with preview flag set to true */
  const getPreviewCourseDescription = useStableCallback(
    async (): Promise<any> => {
      const brandingFileOverrides = await getOverrideFileURLs()
      const startPageGraphicUrl = await updatePageGraphicFile()
      const bookCoverGraphicUrl = await updateBookCoverGraphicFile()

      const courseDescription = getCourseDescription(
        "preview",
        brandingFileOverrides,
        startPageGraphicUrl,
        bookCoverGraphicUrl
      )

      if (courseDescription.scorm_version === "scorm-cmi5") {
        courseDescription.scorm_version = "scorm-1.2"
      }

      return courseDescription
    }
  )

  /**
   * Create the preview content from URL
   * @param previewUrl - The URL for the preview content
   */
  const createPreviewContent = (previewUrl: string) => {
    if (isScorm) {
      // Reach inside folder to get root web page
      const iframeUrl = previewUrl + "/app/index.html"

      return (
        <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
          <iframe
            src={iframeUrl}
            title="Preview"
            style={{ height: "100%", width: "100%", border: "none" }}
          />
        </div>
      )
    } else if (format === "pptx") {
      return (
        <PreviewPowerpointContent
          previewUrl={previewUrl}
          branding={branding}
          slideNumbersMode={addSlideNumbers ? "normal" : "faint"}
        />
      )
    }
  }

  /**
   * Save updated Summary HTML in background when input loses focus, to persist it
   * if user exits without exporting/previewing
   */
  const saveSummaryHTML = useMemo(
    () =>
      debounce((html: string) => {
        const { course: id } = parseQueryString(window.location.search)
        if (id) {
          updateLearningManagement(id, {
            summary_html: html,
          }).then((response) => console.log(response.data))
        }
      }, SAVE_SUMMARY_DEBOUNCE_TIME),
    []
  )

  return {
    courseId,
    asyncJobError,
    onExit,
    isLoading:
      isLoading ||
      formatOptionsRequest.loading ||
      lmsOptionsRequest.loading ||
      prerequisiteOptionsState.loading,
    setIsLoading,
    asyncJobStatus,
    modalities,
    handleChangeModalities,
    setModalities,
    audiences,
    handleChangeAudiences,
    setAudiences,
    skillLevel,
    handleChangeSkillLevel,
    highLevelGoal,
    handleChangeHighLevelGoal,
    prerequisites,
    handleChangePrerequisites,
    setPrerequisites,
    preRequisiteCourseList: prerequisiteOptionsState.options,
    format,
    handleChangeFormat,
    formatOptions: formatOptionsRequest.formatOptions,
    scormVersion,
    handleChangeScormVersion,
    lmsTargets: lmsOptionsRequest.lmsTargets,
    passingScore,
    handleChangePassingScore,
    showCorrectAnswer,
    handleChangeShowCorrectAnswer,
    animateListItems,
    handleChangeAnimateListItems,
    courseExportTitle,
    handleChangeCourseExportTitle,
    scrollAnimation,
    handleChangeScrollAnimation,
    restrictNavigation,
    handleChangeRestrictNavigation,
    mustPassQuizzes,
    handleChangeMustPassQuizzes,
    scormSeparateSections,
    handleChangeScormSeparateSections,
    scormExcludeCourseNavigation,
    handleChangeScormExcludeCourseNavigation,
    scormEnableImageZoom,
    handleChangeScormEnableImageZoom,
    scormDisableAccessibleControls,
    handleChangeScormDisableAccessibleControls,
    scormHideSectionHeadings,
    handleChangeScormHideSectionHeadings,
    scormHideDuration,
    handleChangeScormHideDuration,
    scormExcludeSummarySection,
    handleChangeScormExcludeSummarySection,
    scormSubSectionNavigation,
    handleChangeScormSubSectionNavigation,
    excludeExercises,
    handleChangeExcludeExercises,
    excludeDemonstrations,
    handleChangeExcludeDemonstrations,
    excludeQuizzes,
    handleChangeExcludeQuizzes,
    addSlideNumbers,
    handleChangeAddSlideNumbers,
    startPageGraphic,
    handleChangeStartPageGraphicFile,
    bookCoverGraphic,
    handleChangeBookCoverGraphicFile,
    separateDemonstrationsAndExcercises,
    handleChangeSeparateDemonstrationsAndExcercises,
    summaryHtml,
    setSummaryHtml,
    registrationCap,
    handleChangeRegistrationCap,
    learnerUrl,
    handleExportAssets,
    handleExportAssetsToLocal,
    handleExportAssetsToGoogleDrive,
    handleExportAssetsToLms,
    courseTitle,
    showLearnerLink,
    setShowLearnerLink,
    lmsExportLink,
    showLmsExportLink,
    showLmsDeleteWarning,
    setShowLmsExportLink,
    setShowLmsDeleteWarning,
    showLmsReplaceDialog,
    setShowLmsReplaceDialog,
    showGoogleDriveUploadResult,
    setShowGoogleDriveUploadResult,
    handleReplaceSkillJar,
    publishedCourse,
    branding,
    tone,
    handleChangeTone,
    overrides,
    restoreDefaultBranding,
    canPreview,
    handlePreview,
    getPreviewCourseDescription,
    createPreviewContent,
    useServerlessExport,
    isScorm,
    inaccessibleElements,
    noExportMessage,
    canExport,
    saveSummaryHTML,
    courseHtml,
    translationLanguageCode,
    handleChangeTranslationLanguageCode,
    ...brandingProps,
    noRewriteSlides,
    handleChangeNoRewriteSlides,
  }
}

export default useCourseExport

import { ExpandLess, ExpandMore, PlaylistAddCheck } from "@mui/icons-material"
import { Stack, Button, Collapse, CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import CheckboxSelect from "../../../../microlearning/CheckboxSelect"
import { OrganizationStrategy } from "../../../../microlearning/utils/constants"
import pluralize from "../../../../outline/utils/pluralize"

interface MicrolearningSectionSelectProps {
  optionList: { key: string; label: string | null; duration: any }[]
  checkedOptions: string[]
  updateField: any
  organizeBy: OrganizationStrategy
  isLoading: boolean
  previewIndex: number
  setPreviewIndex: (index: number) => void
  courseCount: number
}

/**
 * Component for selecting sections/concepts in the MicroLearning form. Section selection
 * can be collapsed/expanded to preserve space
 * @param props - Component props
 */
const MicrolearningSectionSelect = (props: MicrolearningSectionSelectProps) => {
  const {
    optionList,
    checkedOptions,
    updateField,
    organizeBy,
    isLoading,
    previewIndex,
    setPreviewIndex,
    courseCount,
  } = props

  const deselectAllDisabled =
    organizeBy === OrganizationStrategy.FullDocument || courseCount === 0

  const selectAllDisabled =
    organizeBy === OrganizationStrategy.FullDocument ||
    courseCount === optionList.length

  const [sectionListOpen, setSectionListOpen] = useState(false)

  const isConcept = organizeBy === OrganizationStrategy.Concept

  const buttonText = isLoading
    ? `Loading ${isConcept ? "concepts" : "sections"}`
    : selectAllDisabled
    ? `All ${isConcept ? "concepts" : "sections"} selected`
    : `${courseCount} of ${pluralize(
        isConcept ? "concept" : "section",
        optionList.length,
        true
      )} selected`

  /**
   * When the sections/concepts are done loading, expand the section list
   */
  useEffect(() => {
    if (!isLoading) {
      setSectionListOpen(true)
    }
  }, [isLoading])

  return (
    <Stack width="100%">
      <Stack direction="row" justifyContent="flex-end">
        <Button
          startIcon={<PlaylistAddCheck />}
          variant="text"
          disabled={isLoading}
          fullWidth
          onClick={() => setSectionListOpen(!sectionListOpen)}
          endIcon={
            isLoading ? (
              <CircularProgress size={16} />
            ) : sectionListOpen ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          }
        >
          {buttonText}
        </Button>
      </Stack>

      <Collapse in={!isLoading && sectionListOpen}>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            disabled={selectAllDisabled}
            onClick={() => {
              updateField(
                "checkedOptions",
                optionList.map((option: any) => option.key)
              )
            }}
          >
            Select All
          </Button>
          <Button
            disabled={deselectAllDisabled}
            onClick={() => {
              updateField("checkedOptions", [])
            }}
          >
            Deselect All
          </Button>
        </Stack>
        {/** Negative margin to account for padding on parent */}
        <Stack marginX={-2}>
          <CheckboxSelect
            data-cy="microlearning-checkbox"
            organizeBy={organizeBy}
            options={optionList}
            selection={checkedOptions}
            onSelectionChange={(selected: any) =>
              updateField("checkedOptions", selected)
            }
            previewIndex={previewIndex}
            onPreviewIndexChange={setPreviewIndex}
            loading={isLoading}
          />
        </Stack>
      </Collapse>
    </Stack>
  )
}

export default MicrolearningSectionSelect

import { useCallback, useEffect, useMemo, useState } from "react"
import fetchDirectoryItems from "../api/fetchDirectoryItems"
import { SortKey } from "../../welcome/hooks/useCoursesDisplay"
import selectUploadJobs from "../../../store/jobs/selectUploadJobs"
import { useSelector } from "react-redux"
import useUploadCompletions from "../../../hooks/useUploadCompletions"
import { sortList } from "../../welcome/hooks/useCoursesDisplay"
import { useFlag } from "../../../utilities/feature-management"
import { useHistory } from "react-router"
import moveDirectoryItem from "../api/moveDirectoryItem"
import createFolder from "../api/createFolder"
import renameFolder from "../api/renameFolder"
import addVirtualFolder from "../utils/addVirtualFolder"
import { Item, ItemsState } from "../types"
import selectItemsForFolder from "../selectors/selectItemsForFolder"
import moveVirtualItems from "../utils/moveVirtualItems"
import normalizeItems from "../utils/normalizeItems"
import addVirtualCompletedUploads from "../utils/addVirtualCompletedUploads"
import { useSnackbar } from "notistack"
import getMovedCompleteMessage from "../utils/getMovedCompleteMessage"
import createFolderTitleMap from "../utils/createFolderTitleMap"

/**
 * Hook for handling management of Directory Items (courses and folders together)
 */
const useDirectoryItems = () => {
  const [error, setError] = useState()
  const [query, setQuery] = useState("")
  const [searchResults, setSearchResults] = useState<any>([])
  const [initialLoad, setInitialLoad] = useState<boolean>(true) // When first mounting, it is certain that a load needs to occur
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false)

  const [checkedItems, setCheckedItems] = useState<string[]>([])

  const [itemsState, setItemsState] = useState<ItemsState>({
    ids: { __ALL__: [] },
    entities: {},
  })

  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()

  const foldersEnabled = useFlag("rollout-course-folders")

  useUploadCompletions((uploads: any[]) =>
    setItemsState((prev: ItemsState) =>
      addVirtualCompletedUploads(prev, uploads)
    )
  )

  const [sortBy, setSortBy] = useState<SortKey>(() => {
    const sortMethod = window.localStorage.getItem(
      "courseSortMethod"
    ) as SortKey

    return sortMethod ?? "last_update"
  })

  const [directory, setDirectory] = useState<string | null>(() => {
    const urlParams = new URLSearchParams(history.location.search)
    const folderParam = urlParams.get("folder")

    if (folderParam) {
      return folderParam
    } else {
      return null
    }
  })

  const currentDirectoryID = directory ?? "__ALL__"
  const displaySearchResults = query !== ""

  /**
   * Update state and local storage for sort option
   * @param option - Sort option
   */
  const handleSelectSortOption = (option: SortKey) => {
    window.localStorage.setItem("courseSortMethod", option)
    setSortBy(option)
  }

  /**
   * Enter directory, update state and folder query string for current directory.
   * @param directoryID - Folder that we want to see the contents of
   */
  const handleEnterDirectory = useCallback(
    (directoryID: string) => {
      setDirectory(directoryID)
      setCheckedItems([])
      history.push({
        pathname: "/",
        search: `?folder=${directoryID}`,
      })
    },
    [history]
  )

  /**
   * Return to root directory, and clear URL of folder query string.
   */
  const handleExitDirectory = useCallback(() => {
    setCheckedItems([])
    setDirectory(null)
    history.push({
      pathname: "/",
    })
  }, [history])

  /**
   * Redirect to editor with course ID when clicked
   * @param e - Click event
   * @param item - Directory item selected
   */
  const handleItemSelected = useCallback(
    (e: any, item: any) => {
      e.preventDefault()
      if (!item.id || item.isLoading) {
        return
      }
      if (item.item_type === "folder") {
        handleEnterDirectory(item.id)
      } else {
        history.push(`/editor/?course=${item.id}`)
      }
    },
    [handleEnterDirectory, history]
  )

  /**
   * Send request to move items
   * @param items - Items to move
   * @param destination - Folder to move items to
   */
  const handleMoveItems = (items: Item[], destination: Item) => {
    const item_ids = items.map((item) => item.id)
    const destination_folder = destination?.id ?? null

    // Optimistically remove the selected items from the current folder to simulate item move
    setItemsState((prev: ItemsState) =>
      moveVirtualItems(prev, item_ids, currentDirectoryID)
    )

    // Provide toast feedback message
    enqueueSnackbar(getMovedCompleteMessage(items, destination), {
      variant: "info",
    })

    moveDirectoryItem({ item_ids, destination_folder }).then(() => {
      refreshItems()
    })
  }

  /**
   * Send request to create new folder
   * @param title - Title for new folder
   */
  const handleCreateDirectory = (title: string) => {
    // Optimistically add the new folder sorted within the rest of the folders before reload
    setItemsState((prev: ItemsState) => addVirtualFolder(prev, sortBy, title))

    createFolder({ title }).then(() => {
      refreshItems()
    })
  }

  /**
   * Send request to update folder's title
   * @param id - ID of folder to rename
   * @param new_title - New title for folder
   */
  const handleRenameDirectory = (id: string, new_title: string) => {
    renameFolder({ id, new_title }).then(() => {
      refreshItems()
    })
  }

  /**
   * Redirect to course upload screen
   * @param e - Click event
   */
  const handleCreateCourseClicked = (e: any) => {
    e.preventDefault()
    history.push("/create-course", {
      directory,
    })
  }

  const fetchItems = useCallback(() => {
    return fetchDirectoryItems({
      folder: currentDirectoryID === "__ALL__" ? null : currentDirectoryID,
      sort_order: sortBy,
    })
      .then((data: any) => {
        setInitialLoad(false)
        setItemsState((initialState: ItemsState) =>
          normalizeItems(initialState, data, currentDirectoryID)
        )
      })
      .catch((error) => {
        setError(error)
        setInitialLoad(false)
      })
  }, [currentDirectoryID, sortBy])

  const searchItems = useCallback(() => {
    setLoadingSearch(true)
    return fetchDirectoryItems({
      folder: null,
      sort_order: sortBy,
      search_query: query || null,
    })
      .then((data) => {
        setLoadingSearch(false)
        setSearchResults(data.items)
      })
      .catch((error) => {
        setError(error)
        setLoadingSearch(false)
      })
  }, [query, sortBy])

  /**
   * Refresh displayed list of items, either by search query or regular folder structure
   */
  const refreshItems = useCallback(() => {
    if (displaySearchResults) {
      handleExitDirectory()
      searchItems()
    } else {
      fetchItems()
    }
  }, [displaySearchResults, fetchItems, handleExitDirectory, searchItems])

  /**
   * When sortBy method changes, clear the IDs map to allow for new sorting
   */
  useEffect(() => {
    setInitialLoad(true) // Setting initial load will render Loading skeleton instead of "No courses" indicator
    setItemsState((state: any) => ({
      ...state,
      ids: {
        __ALL__: [],
      },
    }))
  }, [sortBy])

  /**
   * Upon mounting, perform initial fetch
   */
  useEffect(() => {
    refreshItems()
  }, [directory, sortBy, fetchItems, refreshItems])

  const loading = displaySearchResults
    ? loadingSearch
    : itemsState.ids[currentDirectoryID] === undefined || initialLoad
  const uploadsInProgress = useSelector(selectUploadJobs)

  const items = useMemo(
    () =>
      selectItemsForFolder(itemsState, currentDirectoryID, uploadsInProgress),
    [currentDirectoryID, itemsState, uploadsInProgress]
  )

  const folderTitleMap = useMemo(
    () => createFolderTitleMap(itemsState),
    [itemsState]
  )

  const currentDirectoryTitle = useMemo(
    () => folderTitleMap[currentDirectoryID] ?? null,
    [currentDirectoryID, folderTitleMap]
  )

  const displayedItems = foldersEnabled
    ? displaySearchResults
      ? searchResults
      : items
    : sortList(sortBy, items)

  return {
    error,
    handleItemSelected,
    refreshItems,
    loading,
    items: displayedItems,
    checkedItems,
    setCheckedItems,
    currentFolder: currentDirectoryTitle,
    handleSelectSortOption,
    sortBy,
    handleCreateCourseClicked,
    handleEnterDirectory,
    handleExitDirectory,
    handleMoveItems,
    handleCreateDirectory,
    handleRenameDirectory,
    directory,
    foldersEnabled,
    query,
    setQuery,
    displaySearchResults,
    folderTitleMap,
  }
}

export default useDirectoryItems

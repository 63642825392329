import { styled } from "@mui/material/styles"
import isPropValid from "@emotion/is-prop-valid"
import { List, Theme } from "@mui/material"

type StyledListProps = {
  theme: Theme
}

export const ItemList = styled(List, {
  shouldForwardProp: isPropValid,
})(({ theme }: StyledListProps) => ({
  backgroundColor: theme.scheme.onPrimary,
  overflowX: "hidden",
  overflow: "auto",
  flex: "1 1 0",
  paddingTop: 0,
  paddingBottom: 0,
  borderBottomLeftRadius: "inherit",
  borderBottomRightRadius: "inherit",
}))

export default ItemList

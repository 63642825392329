import useQuery from "./useQuery"

/**
 * extracts the course query parameter from the URL
 * @returns {string} - the value of the course parameter
 */
function useCourseId() {
  const query = useQuery()
  return query.get("course")
}

export default useCourseId

import { createInsertVideoDialog } from "../features/insertVideo/InsertVideoDialog"
import onCreateVideo from "../features/insertVideo/onCreateVideo"
import Froalaeditor from "froala-editor"
import createIFrame from "../features/insertVideo/utils/createIFrame"

// Define icon for our custom video insert button
Froalaeditor.DefineIcon("showVideoDialog", {
  SVG_KEY: "insertVideo",
})

Froalaeditor.DefineIcon("replaceVideoDialog", {
  SVG_KEY: "replaceImage",
})

/**
 * Register custom command to show Video Insert dialog instead of the built-in Froala one.
 * @param displayReactElement - Function to render edit Dialog box
 */
const registerVideoCommands = (displayReactElement: any) => {
  Froalaeditor.RegisterCommand("customVideoReplace", {
    title: "Replace Video",
    icon: "replaceImageDialog",
    focus: true,
    undo: true,
    callback: function () {
      const existingVideo = this.video.get()

      displayReactElement((onClose: () => void) =>
        createInsertVideoDialog((url) => {
          onClose()
          if (existingVideo && url) {
            const videoIFrame = createIFrame(url)
            const videoElement = existingVideo[0] // The actual video element is the first entry in a JQuery array
            videoElement.insertAdjacentHTML("afterend", videoIFrame)
            videoElement.remove()
          }
        })
      )
    },
  })
  Froalaeditor.RegisterCommand("showVideoDialog", {
    title: "Insert Video",
    icon: "showVideoDialog",
    focus: true,
    undo: true,
    callback: function () {
      const selection = this.selection.get() as any
      const anchorElement = selection.anchorNode
      anchorElement.innerHTML = "<br/>"

      displayReactElement((onClose: () => void) =>
        onCreateVideo(anchorElement, onClose, this)
      )
    },
  })
}

export default registerVideoCommands

/**
 * Check if Google OAuth token is still valid
 * @param token - token to check
 * @returns true if token is expired, false if still valid
 */
export const isAccessTokenExpired = async (token: string): Promise<boolean> => {
  const response = await fetch(
    `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${token}`
  )
  const data = await response.json()
  return data.error ? true : false
}

/* eslint-disable jsdoc/require-jsdoc */

import { grey, red } from "@mui/material/colors"
import { createTheme } from "@mui/material/styles"
import { alpha } from "@mui/material"
import materialTheme from "./material-theme.json"
import { CSSProperties } from "react"
type schemeColorMapType = {
  [key: string]: string
}

const lightColourScheme: schemeColorMapType = materialTheme.schemes.light

type Scheme = typeof lightColourScheme

declare module "@mui/material/styles" {
  interface Theme {
    scheme: Scheme
  }

  interface TypographyVariants {
    titleLarge: CSSProperties
    titleMedium: CSSProperties
    titleSmall: CSSProperties
    bodyLarge: CSSProperties
    bodyMedium: CSSProperties
    bodySmall: CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    titleLarge?: CSSProperties
    titleMedium?: CSSProperties
    titleSmall?: CSSProperties
    bodyLarge?: CSSProperties
    bodyMedium?: CSSProperties
    bodySmall?: CSSProperties
  }

  interface Palette {
    tertiary: Palette["primary"]
    neutral: any
    addition: any
    removal: any
    lexWhite: string
    lexLightGrey: string
    lexGrey: string
    lexBlack: string
    outline: string
  }

  interface PaletteOptions {
    tertiary: PaletteOptions["primary"]
    neutral?: any
    addition?: any
    removal?: any
    lexWhite?: string
    lexLightGrey?: string
    lexGrey?: string
    lexBlack?: string
    outline?: string
  }

  interface ThemeOptions {
    scheme: Scheme
  }
}

// Update the Button's color options to include an tertiary option
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary: true
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    titleLarge: true
    titleMedium: true
    titleSmall: true
    bodyLarge: true
    bodyMedium: true
    bodySmall: true
  }
}

const typographyBodyVariants = {
  titleLarge: {
    fontWeight: 400,
    fontSize: "22px",
    color: lightColourScheme.onBackground,
  },
  titleMedium: {
    fontWeight: 500,
    fontSize: "16px",
    color: lightColourScheme.onBackground,
  },
  titleSmall: {
    fontWeight: 500,
    fontSize: "14px",
    color: lightColourScheme.onBackground,
  },
  bodyLarge: {
    fontWeight: 400,
    fontSize: "16px",
    color: lightColourScheme.onBackground,
  },
  bodyMedium: {
    fontWeight: 400,
    fontSize: "14px",
    color: lightColourScheme.onBackground,
  },
  bodySmall: {
    fontWeight: 300,
    fontSize: "12px",
    color: lightColourScheme.onBackground,
  },
}

const updatedTheme = createTheme({
  typography: {
    fontFamily: ['"Roboto"', '"Arial"', '"Helvetica"', '"sans-serif"'].join(
      ","
    ),
    ...typographyBodyVariants,
    h1: {
      fontWeight: 400,
      fontSize: "57px",
      lineHeight: "64px",
    },
    h2: {
      fontWeight: 400,
      fontSize: "45px",
      lineHeight: "52px",
    },
    h3: {
      fontWeight: 400,
      fontSize: "36px",
      lineHeight: "44px",
    },
    h4: {
      fontWeight: 400,
      fontSize: "36px",
    },
    h5: {
      fontWeight: 500,
    },
  },
  scheme: {
    ...lightColourScheme,
  },
  palette: {
    primary: {
      main: lightColourScheme.primary,
      light: lightColourScheme.primaryContainer,
      dark: lightColourScheme.onPrimaryContainer,
    },
    secondary: {
      main: lightColourScheme.secondary,
      light: lightColourScheme.secondaryContainer,
    },
    tertiary: {
      main: lightColourScheme.tertiary,
      light: lightColourScheme.tertiaryContainer,
    },
    neutral: {
      light: grey[300],
      main: grey[600],
    },
    addition: {
      light: "#80d5c2",
      main: "#00AB84",
      dark: "#005642",
    },
    removal: {
      main: red["800"],
    },
    text: {
      primary: "#333333",
      secondary: "#707070",
    },
    lexWhite: lightColourScheme.onPrimary,
    lexLightGrey: "#e7e8ef",
    lexGrey: "#707070",
    lexBlack: "#333333",
    outline: "#71787E",
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return {
            display: "flex",
            alignItems: "center",
            lineHeight: "normal",
            fontSize: "14px",
            textTransform: "none",
            paddingLeft: "24px",
            paddingRight: "24px",
            width: "fit-content",
            borderRadius: "40px",
            height: "40px",
            fontWeight: "500",
            ...(ownerState.startIcon != null && {
              /** If there is a start Icon, clear the left padding for the icon */
              paddingLeft: "0",
            }),
          }
        },
        startIcon: {
          marginLeft: "16px",
        },
        text: ({ ownerState, theme: { scheme } }) => {
          const buttonColour =
            scheme[ownerState.color ?? "primary"] ??
            updatedTheme.palette.primary.main
          return {
            "&:hover": {
              backgroundColor: alpha(buttonColour, 0.08),
            },
            "&:disabled": {
              color: buttonColour,
              opacity: 0.38,
            },
          }
        },
        outlined: ({ ownerState, theme: { scheme } }) => {
          const buttonColour =
            scheme[ownerState.color ?? "primary"] ??
            updatedTheme.palette.primary.main
          return {
            color: buttonColour,
            border: `1px solid ${scheme.outline}`,
            "&:hover": {
              backgroundColor: alpha(buttonColour, 0.08),
              border: `1px solid ${scheme.outline}`,
            },
            "&:disabled": {
              color: scheme.primary,
              opacity: 0.38,
              border: `1px solid ${scheme.outline}`,
            },
          }
        },
        contained: ({ ownerState, theme: { scheme, shadows } }) => {
          const buttonColour =
            scheme[ownerState.color ?? "primary"] ??
            updatedTheme.palette.primary.main
          return {
            boxShadow: "none",
            color: scheme.onPrimary,
            backgroundColor: buttonColour,
            "&:hover": {
              // Simulate state layer overtop
              backgroundColor: buttonColour,
              boxShadow: shadows[2],
              "&::before": {
                transition: "background-color 1s ease",
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: alpha(scheme.onPrimary, 0.08),
                pointerEvents: "none",
              },
            },
            "&:disabled": {
              color: scheme.onPrimary,
              backgroundColor: scheme.primary,
              opacity: 0.38,
            },
          }
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: "32px",
          borderRadius: "8px",
          paddingLeft: 0,
          fontWeight: "500",
        },
        outlined: ({ theme: { scheme } }) => ({
          borderColor: scheme.outline,
        }),
        label: {
          paddingLeft: "16px",
          paddingRight: "16px",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",

          /**
           * Round corners of ToggleButton group
           */
          "&:first-child": {
            borderRadius: "40px 0 0 40px",
          },
          "&:last-child": {
            borderRadius: "0 40px 40px 0",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          borderBottom: "none",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
        },
      },
    },
  },
})

export default updatedTheme

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  useMediaQuery,
} from "@mui/material"
import { forwardRef } from "react"
import { useTheme, Theme } from "@mui/material/styles"
import { LmsCourse } from "../../api/fetchLmsCourse"
import { dialogConfig } from "../../config/dialogConfig"

interface LmsExportReplaceDialogProps {
  open: boolean
  publishedCourse: LmsCourse | null
  onClose: () => void
  onReplace: () => void
  onCreateNew: () => void
}

/**
 * Prompt the user to replace or createa new course
 *
 * @param root0 - LmsExportReplaceDialogProps
 * @param root0.open - open the dialog
 * @param root0.publishedCourse - url of course to replace
 * @param root0.onClose - called when closed
 * @param root0.onReplace - called when replace clicked
 * @param root0.onCreateNew - called when new clicked
 */
const LmsExportReplaceDialog = ({
  open,
  onClose,
  onReplace,
  onCreateNew,
  publishedCourse,
}: LmsExportReplaceDialogProps) => {
  const fullScreen = useFullscreenDialog()

  return (
    <Dialog
      {...dialogConfig}
      fullScreen={fullScreen}
      scroll={fullScreen ? "paper" : "body"}
      open={open}
      onClose={onClose}
      sx={{
        ".MuiDialog-paperFullScreen": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle>Replace or Create New Course</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          This course has already been published to Skilljar. Would you like to
          replace it or create a new course?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {publishedCourse && (
          <Button
            href={publishedCourse.url}
            target="_blank"
            rel="noopener noreferrer"
            fullWidth
          >
            View in Skilljar
          </Button>
        )}
        <Button
          onClick={(e) => {
            e.preventDefault()
            onReplace()
            onClose()
          }}
          variant="contained"
          color="primary"
        >
          Replace Existing
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault()
            onCreateNew()
            onClose()
          }}
          variant="outlined"
          color="primary"
        >
          Create New
        </Button>
      </DialogActions>
    </Dialog>
  )
}

LmsExportReplaceDialog.defaultProps = {
  open: false,
}

export default LmsExportReplaceDialog

/** Use the full screen when it's small */
const useFullscreenDialog = () => {
  const theme: Theme = useTheme()
  return useMediaQuery(theme.breakpoints.down("sm"))
}

const Transition = forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

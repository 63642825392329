import { Alert, Typography, Button, CircularProgress, Box } from "@mui/material"
import FroalaEditor from "react-froala-wysiwyg"
import { minimalFroalaConfig } from "../../custom-elements/minimalFroalaConfig"
import { FormControlRoot } from "./ExportScreen.styles"
import { useState } from "react"
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"
import { generateCourseSummary } from "../../api"
import { useFlag } from "../../utilities/feature-management"

export interface CourseSummaryEditorProps {
  /** Whether the summary section is excluded from the export */
  scormExcludeSummarySection: boolean
  /** The HTML content to display in the editor */
  summaryHtml: string
  /** The function to call when the HTML content changes */
  setSummaryHtml: (html: string) => void
  /** The function to call when the editor loses focus */
  saveSummaryHTML: (html: string) => void
  /** The HTML content of the course */
  courseHtml: string
}

/**
 * Editor component for the course summary section in the export screen
 *
 * @param props - The props for the component
 * @param props.scormExcludeSummarySection - Whether the summary section is excluded from the export
 * @param props.summaryHtml - The HTML content to display in the editor
 * @param props.setSummaryHtml - The function to call when the HTML content changes
 * @param props.saveSummaryHTML - The function to call when the editor loses focus
 * @param props.courseHtml - The HTML content of the course
 */
export function CourseSummaryEditor({
  scormExcludeSummarySection,
  summaryHtml,
  setSummaryHtml,
  saveSummaryHTML,
  courseHtml,
}: CourseSummaryEditorProps) {
  const [isGenerating, setIsGenerating] = useState(false)
  const [generatedHtml, setGeneratedHtml] = useState("")
  const [showAIInterface, setShowAIInterface] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const rolloutAIGenerateCourseSummary = useFlag(
    "rollout-ai-generate-course-summary"
  )

  /**
   * Handle the click event for the generate with AI button
   */
  const handleGenerateClick = async () => {
    setIsGenerating(true)
    setShowAIInterface(true)
    setError(null)

    try {
      const response = await generateCourseSummary(courseHtml)
      setGeneratedHtml(response.summary)
    } catch (error) {
      console.error("Failed to generate summary:", error)
      setError("Failed to generate summary. Please try again.")
      setShowAIInterface(false)
    } finally {
      setIsGenerating(false)
    }
  }

  /**
   * Handle the keep event for the AI generation interface
   * @param newHtml - The new HTML content to apply
   */
  const handleKeep = (newHtml: string) => {
    setSummaryHtml(newHtml)
    saveSummaryHTML(newHtml)
    setShowAIInterface(false)
    setError(null)
  }

  /**
   * Handle the discard event for the AI generation interface
   */
  const handleDiscard = () => {
    setShowAIInterface(false)
    setGeneratedHtml("")
    setError(null)
  }

  return (
    <FormControlRoot id="cool">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        <Typography variant="body1" fontWeight="bold" id="summary-html-label">
          Course Summary
        </Typography>
        {!scormExcludeSummarySection && rolloutAIGenerateCourseSummary && (
          <Button
            startIcon={<AutoFixHighIcon />}
            variant="text"
            size="small"
            onClick={handleGenerateClick}
            disabled={showAIInterface}
          >
            Generate with AI
          </Button>
        )}
      </Box>

      {error && (
        <Alert
          severity="error"
          sx={{ mt: 1, mb: 1 }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}

      {scormExcludeSummarySection ? (
        <Alert severity="warning" sx={{ mt: 1 }}>
          The summary section is currently excluded from the export. Enable the
          summary section in the export settings to edit the course summary.
        </Alert>
      ) : showAIInterface ? (
        <AIGenerationInterface
          onKeep={handleKeep}
          onDiscard={handleDiscard}
          generatedHtml={generatedHtml}
          isLoading={isGenerating}
        />
      ) : (
        <MinimalHtmlEditor
          data-cy="export-course-course-summary-html-editor"
          data-id="export-course-summary-editor"
          html={summaryHtml}
          onChange={setSummaryHtml}
          onBlur={(html: string) => {
            saveSummaryHTML(html)
          }}
        />
      )}
    </FormControlRoot>
  )
}

interface AIGenerationInterfaceProps {
  onKeep: (generatedHtml: string) => void
  onDiscard: () => void
  generatedHtml: string
  isLoading: boolean
}

/**
 * Interface for the AI generation interface component that appears
 * when the user clicks the "Generate with AI" button
 *
 * @param props - The props for the component
 * @param props.onKeep - Callback function to keep and apply the generated HTML
 * @param props.onDiscard - Callback function to discard the generated HTML and return to editor
 * @param props.generatedHtml - The HTML string generated by the AI
 * @param props.isLoading - Boolean indicating if generation is in progress
 */
function AIGenerationInterface({
  onKeep,
  onDiscard,
  generatedHtml,
  isLoading,
}: AIGenerationInterfaceProps) {
  return (
    <Box>
      {isLoading ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={2}
          sx={{
            height: 310,
            justifyContent: "center",
            border: 1,
            borderColor: "divider",
            p: 2,
            mb: 2,
            borderRadius: 1,
          }}
        >
          <CircularProgress />
          <Typography>Generating summary...</Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              height: 310,
              overflow: "auto",
              border: 1,
              borderColor: "divider",
              p: 2,
              mb: 2,
              borderRadius: 1,
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: generatedHtml }} />
          </Box>
          <Box display="flex" gap={2}>
            <Button
              variant="contained"
              onClick={() => onKeep(generatedHtml)}
              color="primary"
            >
              Apply
            </Button>
            <Button variant="outlined" onClick={onDiscard} color="secondary">
              Discard
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}

/**
 * A minimalistic HTML editor
 * @param props - The props for the component
 * @param props.html - The HTML content to display in the editor
 * @param props.onChange - The function to call when the HTML content changes
 * @param props.onBlur - The function to call when editor loses focus
 */
function MinimalHtmlEditor(props: {
  html: string
  onChange: (html: string) => void
  onBlur: (html: string) => void
}) {
  return (
    <FroalaEditor
      tag="textarea"
      config={{
        ...minimalFroalaConfig,
        events: {
          /**
           * Invoke parent callback when input loses focus
           */
          blur: function () {
            const editor = this as any
            const html = editor.html.get()
            props.onBlur(html)
          },
        },
      }}
      model={props.html}
      onModelChange={props.onChange}
    />
  )
}

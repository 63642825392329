import { DialogProps, Slide } from "@mui/material"
import { forwardRef } from "react"

export const Transition = forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const dialogConfig: DialogProps = {
  fullWidth: true,
  maxWidth: "sm",
  keepMounted: true,
  TransitionComponent: Transition,
  open: false,
}

import styled from "@emotion/styled"
import FormControl from "@mui/material/FormControl"
import isPropValid from "@emotion/is-prop-valid"

/**
 * The root form control for the export screen with some default styles
 */
export const FormControlRoot = styled(FormControl, {
  shouldForwardProp: isPropValid,
})(() => ({
  marginTop: 16,
  width: "100%",
}))

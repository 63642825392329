import { useCallback, useEffect, useRef, useState } from "react"
import { parseQueryString } from "../../../utilities/queryString"
import useGetSections from "./useGetSections"
// @ts-ignore
import { useHistory } from "react-router-dom"
import { OrganizationStrategy } from "../utils/constants"
import submitMicroLearningExport from "../async/submitMicroLearningExport"
import fetchConcepts from "../async/fetchConcepts"
import getMicroLearningPreview from "../async/getMicroLearningPreview"
import { isEqual } from "lodash/fp"
import submitSaveMicroLearningCourses from "../async/submitSaveMicroLearningCourses"
import { useSnackbar } from "notistack"
import fetchFolders from "../../homeNavigation/api/fetchFolders"
import { loadCourse } from "../../../api"
import { ExportLocation } from "../../export/ExportTypes"
import { ExportTargetName } from "../../export/utils/contants"
import { GoogleDriveUploadResult } from "../../export/api/uploadToGoogleDrive"
import initializeEditorBranding from "../../branding/utils/initializeEditorBranding"
import { defaultBranding } from "../../branding/hooks/useBranding"
import { useFlag } from "../../../utilities/feature-management"

export type Option = {
  key: string
  label: string | null
  duration: any
  data?: any | null
}

export type FormData = {
  organizeBy: OrganizationStrategy
  /** Percent to condense content by */
  condensePercent: number
  checkedOptions: any[]
  format: "docx" | "html"
  /** Whether to transform components to text */
  transformComponentsToText: boolean
  /** Whether to transform media to text */
  transformMediaToText: boolean
}

export type FormDataField = keyof FormData

export interface AsyncJob {
  status: string
  async_id: string
  data: any
}

/**
 * Hook for handling all logic on Microlearning form
 */
const useMicrolearning = () => {
  const history = useHistory()

  const [courseId, setCourseId] = useState<string>()
  const [headingLevel, setHeadingLevel] = useState<string>("H1")
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingPreview, setIsLoadingPreview] = useState(false)
  const [exportInProgress, setExportInProgress] = useState(false)
  const [optionList, setOptionList] = useState<Option[]>([])
  const [previewIndex, setPreviewIndex] = useState(0)
  const [preview, setPreview] = useState(null)
  const [previewError, setPreviewError] = useState(null)
  const [createPath, setCreatePath] = useState(true)
  const formDataRef = useRef<any>()
  const latestRequestIdRef = useRef<number>(0)
  const [selectedFolder, setSelectedFolder] = useState<string>("all_courses")
  const [availableFolders, setAvailableFolders] = useState<any[]>([])
  const [saveToGoogleResult, setSaveToGoogleResult] =
    useState<GoogleDriveUploadResult | null>(null)
  const [branding, setBranding] = useState<any>(null)

  const newMicrolearning = useFlag("rollout-refreshed-microlearning-ux")

  const [formData, setFormData] = useState<FormData>({
    checkedOptions: [],
    format: "docx",
    organizeBy: OrganizationStrategy.FullDocument,
    condensePercent: 0,
    transformComponentsToText: false,
    transformMediaToText: false,
  })
  const { enqueueSnackbar } = useSnackbar()

  const courseCount =
    formData.organizeBy === OrganizationStrategy.FullDocument
      ? 1
      : formData.checkedOptions.length

  const { fullDocument, fetchSections, availableHeadings } =
    useGetSections(courseId)

  const updateField = useCallback(
    async <Field extends FormDataField>(
      field: Field,
      value: FormData[Field]
    ) => {
      if (field === "checkedOptions") {
        setPreviewIndex(0)
      }
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }))
    },
    []
  )

  const onExit = useCallback(() => {
    history.push("/")
  }, [history])

  const handleHeadingLevelSelect = useCallback((e: any) => {
    const level = e.target.value
    setHeadingLevel(level)
  }, [])

  const handleOrganizeBySelect = useCallback((e: any) => {
    setFormData((prev) => ({
      ...prev,
      checkedOptions: [],
      organizeBy: e.target.value,
    }))
  }, [])

  const triggerReloadPreview = useCallback(() => {
    /**
     * Clear the form data ref so we can re-trigger a preview,
     * and remove the error.
     */
    formDataRef.current = null
    setPreviewError(null)
  }, [])

  /**
   * When form fields or page number changes, re-load preview.
   */
  useEffect(() => {
    if (!courseId) {
      return
    }
    const { organizeBy, checkedOptions } = formData

    // Only proceed with sending the preview request if the form data fields have changed
    if (
      formDataRef.current &&
      isEqual(formDataRef.current, { ...formData, previewIndex })
    ) {
      return
    }

    // Don't send the request if we are sectioning the course and no sections are selected
    if (
      organizeBy !== OrganizationStrategy.FullDocument &&
      checkedOptions.length === 0
    ) {
      return
    }

    formDataRef.current = { previewIndex, ...formData }

    const data = {
      courseId,
      ...formData,
      optionId: checkedOptions[previewIndex],
      optionList,
    }

    setIsLoadingPreview(true)

    // Increment request counter to track latest request
    const currentRequestId = ++latestRequestIdRef.current

    getMicroLearningPreview(data)
      .then((data) => {
        // Only update preview if this is still the latest request
        if (currentRequestId === latestRequestIdRef.current) {
          setIsLoadingPreview(false)
          setPreview(data)
        }
      })
      .catch((e) => {
        // Only update preview error if this is still the latest request
        if (currentRequestId === latestRequestIdRef.current) {
          setPreviewError(e)
          setIsLoadingPreview(false)
        }
      })
  }, [courseId, formData, formData.checkedOptions, optionList, previewIndex])

  /**
   * Retrieve list of folders where we can save microlearning to
   */
  useEffect(() => {
    fetchFolders().then(setAvailableFolders)
  }, [])

  /**
   * Ensure the first available heading option is defaulted
   */
  useEffect(() => {
    if (!courseId || availableHeadings.length === 0) {
      return
    }
    setHeadingLevel(availableHeadings[0].value)
  }, [availableHeadings, courseId])

  useEffect(() => {
    if (!courseId) {
      return
    }

    setIsLoading(true)

    if (
      formData.organizeBy === OrganizationStrategy.FullDocument &&
      fullDocument.label !== ""
    ) {
      updateField("checkedOptions", [])
      setOptionList([fullDocument])
      setIsLoading(false)
      return
    }

    if (formData.organizeBy === OrganizationStrategy.Section) {
      fetchSections(headingLevel).then((result) => {
        if (!result) {
          return
        }
        const options = result.map((section) => ({
          ...section,
          data: null,
        }))
        setOptionList(options)
        updateField(
          "checkedOptions",
          options.map((option: any) => option.key)
        )
        setIsLoading(false)
      })
      return
    }

    if (formData.organizeBy === OrganizationStrategy.Concept) {
      fetchConcepts(courseId).then((concepts) => {
        setIsLoading(false)
        const options = concepts.map((concept: any) => ({
          key: concept.label,
          label: concept.label,
          duration: null,
          data: concept,
        }))
        setOptionList(options)
        updateField(
          "checkedOptions",
          options.map((option: any) => option.key)
        )
      })

      return
    }
  }, [
    courseId,
    fetchSections,
    formData.organizeBy,
    fullDocument,
    headingLevel,
    updateField,
  ])

  useEffect(() => {
    //get the course id to create microlearning from
    const parsedQuery = parseQueryString(window.location.search)
    if (parsedQuery.course) {
      setCourseId(parsedQuery.course)
    } else {
      console.log("error: no course specified")
    }
  }, [])

  // Fetch parent ID of course
  useEffect(() => {
    if (!courseId) {
      return
    }

    loadCourse(courseId).then(({ data }) => {
      console.log(data)
      setSelectedFolder(data.parent_directory ?? "all_courses")
      if (newMicrolearning && data.branding) {
        initializeEditorBranding(data.branding)
          .then(setBranding)
          .catch(() => {
            setBranding(defaultBranding)
          })
      } else {
        setBranding(defaultBranding)
      }
    })
  }, [courseId, newMicrolearning])

  /**
   * user initiated course export to google drive
   * @param location ExportLocation - place to save export
   */
  const handleExportMicrolearningToGoogleDrive = async (
    location: ExportLocation
  ) => {
    handleExportMicroLearning("google-drive", location)
  }

  /**
   * Handler for when user exports Microlearning
   */
  const handleExportMicroLearning = useCallback(
    async (target: ExportTargetName, location?: ExportLocation) => {
      setExportInProgress(true)
      const data = {
        courseId,
        ...formData,
        optionList,
      }

      submitMicroLearningExport(data, target, location)
        .then((result) => {
          setExportInProgress(false)

          if (result != null) {
            setSaveToGoogleResult(result)
          } else {
            onExit()
          }
        })
        .catch((e) => {
          setExportInProgress(false)
          enqueueSnackbar(
            "There was an issue exporting Microlearning courses. Please try again",
            { variant: "error" }
          )
        })
    },
    [courseId, enqueueSnackbar, formData, onExit, optionList]
  )

  /**
   * Handler for when user saves Microlearning as new courses
   */
  const handleSaveAsMicroLearning = useCallback(async () => {
    const data = {
      courseId,
      createPath: courseCount <= 1 ? false : createPath,
      ...formData,
      optionList,
      folder: selectedFolder === "all_courses" ? null : selectedFolder,
    }

    setExportInProgress(true)

    submitSaveMicroLearningCourses(data)
      .then((response) => {
        setExportInProgress(false)
        onExit()
        enqueueSnackbar("Microlearning courses saved.", { variant: "success" })
      })
      .catch((e) => {
        enqueueSnackbar(
          "There was an issue saving Microlearning courses. Please try again",
          { variant: "error" }
        )
      })
  }, [
    courseCount,
    courseId,
    createPath,
    enqueueSnackbar,
    formData,
    onExit,
    optionList,
    selectedFolder,
  ])

  return {
    availableHeadings,
    headingLevel,
    handleHeadingLevelSelect,
    handleOrganizeBySelect,
    handleExportMicroLearning,
    handleSaveAsMicroLearning,
    handleExportMicrolearningToGoogleDrive,
    isLoading,
    exportInProgress,
    optionList,
    formData,
    updateField,
    preview,
    previewError,
    isLoadingPreview,
    previewIndex,
    setPreviewIndex,
    createPath,
    setCreatePath,
    triggerReloadPreview,
    courseCount,
    availableFolders,
    selectedFolder,
    setSelectedFolder,
    saveToGoogleResult,
    onExit,
    setSaveToGoogleResult,
    branding,
  }
}

export default useMicrolearning

import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Theme,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { calloutBoxCustomElement } from "../../../../../custom-elements/calloutBoxCustomElement"
import { categoriesCustomElement } from "../../../../../custom-elements/categoriesCustomElement"
import { CustomElements } from "../../../../../custom-elements/CustomElements"
import { flipCardGridCustomElement } from "../../../../../custom-elements/flipCardGridCustomElement"
import { labeledImageCustomElement } from "../../../../../custom-elements/labeledImageCustomElement"
import { processCustomElement } from "../../../../../custom-elements/processCustomElement"
import { questionCustomElement } from "../../../../../custom-elements/questionCustomElement"
import { styledListCustomElement } from "../../../../../custom-elements/StyledList/styledListCustomElement"
import { tabsCustomElement } from "../../../../../custom-elements/tabsCustomElement"
import PreviewPagination from "../../../../microlearning/PreviewPagination"
import { OrganizationStrategy } from "../../../../microlearning/utils/constants"
import withEditorBranding from "../../../../../components/widgets/Editor/utils/withEditorBranding"

const customElementConfigs = [
  questionCustomElement,
  flipCardGridCustomElement,
  labeledImageCustomElement,
  tabsCustomElement,
  categoriesCustomElement,
  processCustomElement,
  styledListCustomElement,
  calloutBoxCustomElement,
]

/**
 * Component for rendering previews of Micro-Learning
 * @param props - Component props
 * @param props.previews - List of previewed courses
 */
const MicrolearningPreview = (props: any) => {
  const {
    preview,
    previewIndex,
    previewError,
    setPreviewIndex,
    isLoadingPreview,
    triggerReloadPreview,
    formData,
    branding,
  } = props

  const pageNumber = previewIndex + 1
  const [reactElements, setReactElements] = useState<any[]>([])

  const previewCount = formData.checkedOptions.length

  const displayLoading = isLoadingPreview || branding === null

  // Mount custom elements as readOnly into the editor alongside existing HTML
  useEffect(() => {
    const editorDiv = document.getElementsByClassName(
      "editor"
    )[0] as HTMLDivElement

    if (editorDiv) {
      setReactElements([
        <CustomElements
          key="custom-elements"
          editor={null}
          editorDiv={editorDiv}
          configs={customElementConfigs}
          readOnly={true}
        />,
      ])
    }
  }, [preview, isLoadingPreview])

  return (
    <>
      <Paper
        elevation={0}
        sx={(theme: Theme) => ({
          padding: 2,
          borderRadius: "20px",
          backgroundColor: theme.scheme.onPrimary,
          flex: "1 0 0",
          height: "100%",
          maxHeight: "100%",
          overflow: "auto",
          "& img": {
            maxWidth: "100%",
          },
        })}
      >
        {displayLoading ? (
          <Box
            data-cy="microlearning-preview-screen-loading"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {previewError ? (
              <Box
                data-cy="microlearning-preview-screen-error"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                marginTop={20}
              >
                <Typography variant="body2">
                  An error occurred fetching the preview.
                </Typography>
                <Button onClick={triggerReloadPreview}>Retry</Button>
              </Box>
            ) : (
              (formData.organizeBy === OrganizationStrategy.FullDocument ||
                previewCount > 0) &&
              preview && (
                <>
                  {reactElements}
                  <Box
                    className="editor"
                    data-cy="microlearning-preview-screen-loaded"
                  >
                    <Box
                      sx={{ ...withEditorBranding(branding) }}
                      dangerouslySetInnerHTML={{ __html: preview.html }}
                    />
                  </Box>
                </>
              )
            )}
          </>
        )}
        {previewCount > 0 && (
          <Box position="fixed" bottom={40} right={40}>
            <PreviewPagination
              count={previewCount}
              page={pageNumber}
              onChange={(value: number) => setPreviewIndex(value - 1)}
            />
          </Box>
        )}
      </Paper>
    </>
  )
}

export default MicrolearningPreview

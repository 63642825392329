import React from "react"
import { ThemeProvider } from "@mui/material"
import useCourseTheme from "../features/branding/hooks/useCourseTheme"

interface CustomerThemeProviderProps {
  courseID: string
  children: React.ReactNode
  useNewUX?: boolean
}

/**
 * Provider for customer theme context, allowing editor elements
 * to be styled with branding colours
 * @param props Component props
 * @param props.courseID - Course to obtain branding
 * @param props.children -  Children consuming the Customer Theme context
 * @param props.useNewUX - Flag to indicate to use refreshed UX and theme
 */
export const CustomerThemeProvider = ({
  children,
  courseID,
  useNewUX = false,
}: CustomerThemeProviderProps) => {
  const { theme } = useCourseTheme(courseID, useNewUX)
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

import { useTheme } from "@emotion/react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material"

import { Computer, Description } from "@mui/icons-material"
import LanguageIcon from "@mui/icons-material/Language"

import PropTypes from "prop-types"
import GoogleDriveIcon from "../../assets/google-drive.png"

import { useGapi } from "../../contexts/GapiProvider"
import { dialogConfig } from "../../config/dialogConfig"

/**
 * Dialog for choosing sources to create course with
 * @param props - Component props
 */
const SelectDocumentDialog = (props) => {
  const fullScreen = useFullscreenDialog()

  const { isGapiReady } = useGapi()

  const {
    onSelect,
    open,
    useWebImport,
    useGoogleDriveUpload,
    useExistingCourseImport,
  } = props

  return (
    <Dialog
      {...dialogConfig}
      fullScreen={fullScreen}
      open={open}
      maxWidth="xs"
      scroll={fullScreen ? "paper" : "body"}
      onClose={onSelect}
    >
      <DialogTitle>Choose Documents</DialogTitle>
      <DialogContent>
        <List>
          {useExistingCourseImport && (
            <ListItem button divider onClick={() => onSelect("leai")}>
              <ListItemIcon>
                <Description />
              </ListItemIcon>
              <ListItemText>From LEAi</ListItemText>
            </ListItem>
          )}
          <ListItem button divider onClick={() => onSelect("computer")}>
            <ListItemIcon>
              <Computer />
            </ListItemIcon>
            <ListItemText>From your Computer</ListItemText>
          </ListItem>
          {useWebImport && (
            <ListItem button divider onClick={() => onSelect("web")}>
              <ListItemIcon>
                <LanguageIcon />
              </ListItemIcon>
              <ListItemText>From a Web Page</ListItemText>
            </ListItem>
          )}
          {useGoogleDriveUpload && isGapiReady && (
            <ListItem button divider onClick={() => onSelect("google-drive")}>
              <ListItemIcon>
                <img
                  src={GoogleDriveIcon}
                  alt="Google Drive"
                  style={{ width: 24, height: 24 }}
                />
              </ListItemIcon>
              <ListItemText>From Google Drive (Beta)</ListItemText>
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        {useGoogleDriveUpload && isGapiReady && (
          <Typography variant="caption" sx={{ ml: 2, mr: 10 }}>
            Google Drive is a trademark of Google Inc. Use of this trademark is
            subject to Google Permissions.
          </Typography>
        )}
        <Button onClick={() => onSelect("cancel")}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

SelectDocumentDialog.propTypes = {
  open: PropTypes.bool,
  onSelect: PropTypes.func,
}

SelectDocumentDialog.defaultProps = {
  open: false,
}

export default SelectDocumentDialog

const useFullscreenDialog = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down("sm"))
}

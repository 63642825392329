import { useEffect, useState } from "react"
import {
  Box,
  Button,
  Stack,
  Typography,
  Alert,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import AddIcon from "@mui/icons-material/Add"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import QuizIcon from "@mui/icons-material/Quiz"
import ToggleOnIcon from "@mui/icons-material/ToggleOn"
import CompareArrowsIcon from "@mui/icons-material/CompareArrows"
import TextFieldsIcon from "@mui/icons-material/TextFields"
import * as api from "../api"
import {
  QuestionComponent,
  questionCustomElement,
} from "./questionCustomElement"
import { AddableItem } from "./AddableItem"
import AccessibilityWarning from "../components/atoms/AccessibilityWarning"
import { find, values, flatten, isEmpty, slice } from "lodash"
import {
  getContentSectionHeading,
  getSectionElements,
  stripTemporaryElements,
} from "../utilities/smartTemplates"

/**
 * Question object for a generated question
 */
interface Question {
  id: string
  html: string
  data: any
}

/**
 * Question types that can be generated
 */
enum QuestionType {
  MULTIPLE_CHOICE = "multiple_choice",
  TRUE_FALSE = "true_false",
  FILL_IN_BLANK = "fill_in_the_blank",
  SCENARIO_BASED_MULTIPLE_CHOICE = "scenario_based_multiple_choice",
  MATCHING = "matching",
}

/**
 * Object containing all questions, separated by type
 */
type QuestionsByType = Partial<Record<QuestionType, Question[]>>

/**
 * Array of question types that can be selected
 */
const questionTypes = [
  {
    id: QuestionType.MULTIPLE_CHOICE,
    label: "Multiple Choice",
    icon: <CheckBoxOutlineBlankIcon />,
  },
  {
    id: QuestionType.SCENARIO_BASED_MULTIPLE_CHOICE,
    label: "Scenario-based",
    icon: <QuizIcon />,
  },
  {
    id: QuestionType.TRUE_FALSE,
    label: "True/False",
    icon: <ToggleOnIcon />,
  },
  {
    id: QuestionType.MATCHING,
    label: "Matching",
    icon: <CompareArrowsIcon />,
  },
  {
    id: QuestionType.FILL_IN_BLANK,
    label: "Fill in the Blank",
    icon: <TextFieldsIcon />,
  },
]

const allFilters = values(QuestionType)

/** Props for TestQuestionPanel2 */
interface TestQuestionPanelProps {
  /** The Froala editor */
  editor: any
  /** The root element of the custom element in light DOM */
  element: HTMLElement
  /** Callback to close the panel */
  onClose: () => void
  /** Function to wrap children in styles */
  withStyles: (children: React.ReactElement) => React.ReactElement
}

/**
 * Test question panel for new question generation dialog that allows users to select
 * question types to generate and custom instructions for the generation process.
 * @param props See below.
 */
function TestQuestionPanel2(props: TestQuestionPanelProps) {
  const { editor, element, onClose, withStyles } = props

  // Which stage the user is on (selecting question types or viewing generated questions)
  const [stage, setStage] = useState<"select" | "questions">("select")

  // Whether the user is waiting for a response from the API
  const [loading, setLoading] = useState(false)
  // Message to display to the user from the API that is not an error
  const [message, setMessage] = useState("")
  // Error to display to the user
  const [error, setError] = useState<string | null>(null)
  // IDs of questions that have been added
  const [addedIDs, setAddedIDs] = useState<string[]>([])
  // Question types that have been selected
  const [selectedTypes, setSelectedTypes] = useState<string[]>(allFilters)
  // // Custom instructions for the generation process
  // const [customInstructions, setCustomInstructions] = useState("")
  // // Whether custom instructions have been enabled
  // const [customInstructionsEnabled, setCustomInstructionsEnabled] =
  //   useState(false)

  // Abort controller for the API call
  const [abortController, setAbortController] =
    useState<AbortController | null>(null)

  // Generated questions
  const [questions, setQuestions] = useState<QuestionsByType>({})
  // Current page of questions
  const [currentPage, setCurrentPage] = useState(0)
  // Filter for questions
  const [selectedFilter, setSelectedFilter] = useState<QuestionType | null>(
    null
  )

  // Abort the API call when the component unmounts
  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort()
      }
    }
  }, [abortController])

  /**
   * Generate questions by calling the API
   */
  const generateItems = async () => {
    try {
      setLoading(true)
      setError(null)

      // Create new AbortController for this request
      const controller = new AbortController()
      setAbortController(controller)

      // Get the smart template id and heading
      const smartTemplateId = element.dataset.smartTemplateId!
      const smartTemplateHeading = document.getElementById(smartTemplateId)!
      const contentSectionHeading =
        getContentSectionHeading(smartTemplateHeading)!
      let courseHtml = stripTemporaryElements(editor.html.get())

      // Create the smart template
      const data = await api.createTemplate(
        "test_question",
        courseHtml,
        contentSectionHeading.id,
        null,
        {
          question_types: selectedTypes,
        }
        // TODO: { signal: controller.signal } Add abort signal to API call. This depends on axios >= 0.22 which we don't have yet.
      )

      // Set the message from the API
      setMessage(data.data.message ?? "")
      // Reset the added IDs
      setAddedIDs([])

      // Parse the smart template HTML
      const html = data.data.smart_template_html
      const parser = new DOMParser()
      const doc = parser.parseFromString(html, "text/html")
      const elements = [...doc.querySelectorAll("section")]

      // Set the sorted questions and move to the questions stage
      setQuestions(sortQuestions(elements))
      setStage("questions")
    } catch (err) {
      if (err instanceof Error) {
        if (err.name === "AbortError") {
          return
        }
        console.error(err)
        setError(
          "There was an error generating Test Questions. Please try again later, and contact LearnExperts if the problem persists."
        )
      }
    } finally {
      // Reset the loading and abort controller
      setLoading(false)
      setAbortController(null)
    }
  }

  /** Add item to document below control
   * @param item - The item to add.
   */
  function handleAdd(item: Question) {
    // Get template heading
    const smartTemplateId = element.dataset.smartTemplateId!
    const smartTemplateHeading = document.getElementById(smartTemplateId)!

    // Get existing smart template section elements
    const existingSectionElements = getSectionElements(smartTemplateHeading)

    // Add to end of last element in section
    const lastSectionElement =
      existingSectionElements[existingSectionElements.length - 1]
    lastSectionElement.insertAdjacentHTML("afterend", item.html)

    // Remove blank line if it is the last existing element
    const lastExistingElement =
      existingSectionElements[existingSectionElements.length - 1]
    if (lastExistingElement && lastExistingElement.innerHTML === "<br>") {
      lastExistingElement.remove()
    }

    // Remove blank line if it is the first existing element after the content section heading
    const firstExistingElement = existingSectionElements[1]
    if (
      firstExistingElement &&
      firstExistingElement !== lastExistingElement &&
      firstExistingElement.innerHTML === "<br>"
    ) {
      firstExistingElement.remove()
    }

    // Add to list of added indexes
    setAddedIDs((addedIDs) => [...addedIDs, item.id])

    // Save undo step in editor
    editor.undo.saveStep()
  }

  /**
   * Cancel the API call
   */
  const handleCancel = () => {
    if (loading && abortController) {
      abortController.abort()
      setLoading(false)
      setError(null)
    } else {
      onClose()
    }
  }

  /**
   * Selection stage of the component where users select question types and custom
   * instructions for the generation process.
   */
  const SelectionStage = () => (
    <Stack sx={{ height: "100%", overflow: "auto" }}>
      <Stack spacing={2} p={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Stack>
            <Typography variant="subtitle1">Question Types</Typography>
            <Typography variant="body2" color="text.secondary">
              Select the types of questions you want to generate. By default,
              all types are selected.
            </Typography>
          </Stack>
          <Button
            variant="text"
            onClick={() =>
              setSelectedTypes(
                selectedTypes.length === allFilters.length ? [] : allFilters
              )
            }
            disabled={loading}
          >
            {selectedTypes.length === allFilters.length
              ? "Deselect All"
              : "Select All"}
          </Button>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
            gap: 2,
            maxWidth: "900px",
          }}
        >
          {questionTypes.map((type) => {
            const isSelected = selectedTypes.includes(type.id)
            return (
              <QuestionTypeButton
                key={type.id}
                type={type}
                isSelected={isSelected}
                onClick={() => {
                  setSelectedTypes((prev) =>
                    isSelected
                      ? prev.filter((t) => t !== type.id)
                      : [...prev, type.id]
                  )
                }}
                disabled={loading}
              />
            )
          })}
        </Box>
        {/* <Box>
          <FormControlLabel
            control={
              <Switch
                checked={customInstructionsEnabled}
                onChange={(e) => setCustomInstructionsEnabled(e.target.checked)}
                disabled={loading}
              />
            }
            label="Custom Instructions"
          />
          {customInstructionsEnabled && (
            <TextField
              fullWidth
              multiline
              rows={4}
              value={customInstructions}
              onChange={(e) => setCustomInstructions(e.target.value)}
              placeholder="Enter your custom instructions here..."
              sx={{ mt: 2 }}
              disabled={loading}
            />
          )}
        </Box> */}
      </Stack>

      <Box sx={{ mt: "auto" }}>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Box
          display="flex"
          justifyContent="flex-end"
          gap={2}
          p={2}
          sx={{
            // eslint-disable-next-line
            backgroundColor: (theme) => theme.palette.grey[200],
          }}
        >
          <Button
            onClick={handleCancel}
            startIcon={<CloseIcon />}
            variant="text"
            color="primary"
          >
            {loading ? "Cancel Generation" : "Cancel"}
          </Button>
          <Button
            variant="contained"
            onClick={generateItems}
            disabled={selectedTypes.length === 0 || loading}
            startIcon={
              loading && <CircularProgress size={16} color="inherit" />
            }
          >
            {loading ? "Generating..." : "Generate Questions"}
          </Button>
        </Box>
      </Box>
    </Stack>
  )

  /**
   * Questions stage of the component where users view generated questions.
   */
  const QuestionsStage = () => {
    const { filteredQuestions, hasNextPage } = filterQuestions(
      questions,
      selectedFilter ? [selectedFilter] : allFilters,
      currentPage
    )

    const questionList = flatten(values(filteredQuestions))
    const hasQuestions = questionList.length > 0

    return (
      <Stack>
        <Box
          key="content"
          sx={{
            // eslint-disable-next-line
            height: (theme) => theme.spacing(60),
            overflow: "auto",
          }}
        >
          {selectedTypes.length > 0 ? (
            <Box>
              {(Object.keys(filteredQuestions) as QuestionType[]).map(
                (questionType) => {
                  const title = find(
                    questionTypes,
                    (o) => o.id === questionType
                  )?.label
                  const questionSubset = filteredQuestions[questionType]
                  const isMatching = questionType === QuestionType.MATCHING

                  return questionSubset && questionSubset.length > 0 ? (
                    <Box key={questionType}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        p={2}
                      >
                        <Typography variant="subtitle2" fontWeight={700}>
                          {title}
                        </Typography>
                        {isMatching && (
                          <AccessibilityWarning
                            placement="left-end"
                            disablePortal
                          />
                        )}
                      </Box>
                      {questionSubset.map((item: Question) => (
                        <AddableItem
                          key={item.id}
                          added={addedIDs.includes(item.id)}
                          onAdd={() => handleAdd(item)}
                        >
                          <QuestionComponent data={item.data} />
                        </AddableItem>
                      ))}
                    </Box>
                  ) : null
                }
              )}
            </Box>
          ) : (
            <Stack justifyContent="center" alignItems="center" height="100%">
              <Typography variant="body2">
                Select at least 1 question type to view generated Test
                Questions.
              </Typography>
            </Stack>
          )}
        </Box>
        {message && <Alert severity="warning">{message}</Alert>}
        <Box
          sx={{
            // eslint-disable-next-line
            backgroundColor: (theme) => theme.palette.grey[200],
            p: 2,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Button
                variant="text"
                onClick={() => {
                  setStage("select")
                  setCurrentPage(0)
                }}
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
              <FormControl size="small" sx={{ minWidth: 200 }}>
                <InputLabel>Filter by Type</InputLabel>
                <Select
                  value={selectedFilter ?? "all"}
                  label="Filter by Type"
                  onChange={(e) =>
                    setSelectedFilter(
                      e.target.value === "all"
                        ? null
                        : (e.target.value as QuestionType)
                    )
                  }
                  MenuProps={{
                    disablePortal: true,
                  }}
                >
                  <MenuItem value="all">All Question Types</MenuItem>
                  {questionTypes.map((type) => (
                    <MenuItem
                      key={type.id}
                      value={type.id}
                      disabled={!selectedTypes.includes(type.id)}
                    >
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <Stack direction="row" spacing={1}>
              <Button
                disabled={!hasQuestions}
                variant="text"
                onClick={() => {
                  questionList.forEach((item) => {
                    if (!addedIDs.includes(item.id)) {
                      handleAdd(item)
                    }
                  })
                }}
                startIcon={<AddIcon />}
              >
                Add All
              </Button>
              <Button
                variant="text"
                onClick={() => setCurrentPage((prev) => prev - 1)}
                disabled={currentPage === 0}
                startIcon={<NavigateBeforeIcon />}
              >
                Previous
              </Button>
              <Button
                variant="text"
                onClick={() => setCurrentPage((prev) => prev + 1)}
                disabled={!hasNextPage}
                startIcon={<NavigateNextIcon />}
              >
                Next
              </Button>
              <Button
                variant="text"
                onClick={onClose}
                startIcon={<CloseIcon />}
              >
                Close
              </Button>
            </Stack>
          </Box>
        </Box>
      </Stack>
    )
  }

  return withStyles(
    <Stack
      borderRadius={2}
      sx={{
        // eslint-disable-next-line
        backgroundColor: (theme) => theme.palette.grey[100],
        overflow: "hidden",
        height: "100%",
      }}
    >
      {stage === "select" ? <SelectionStage /> : <QuestionsStage />}
    </Stack>
  )
}

/**
 * Sort the collection of HTML elements into subsets based on question type
 * @param questionElements - Array of all questions in HTML form
 */
const sortQuestions = (questionElements: HTMLElement[]) => {
  let result = {
    [QuestionType.MULTIPLE_CHOICE]: [],
    [QuestionType.TRUE_FALSE]: [],
    [QuestionType.FILL_IN_BLANK]: [],
    [QuestionType.SCENARIO_BASED_MULTIPLE_CHOICE]: [],
    [QuestionType.MATCHING]: [],
  } as QuestionsByType

  questionElements.forEach((element) => {
    // Determine question type
    const questionType = getQuestionType(element)

    if (result[questionType]) {
      result[questionType]!.push({
        id: element.dataset.generatedAs as string,
        html: element.outerHTML,
        data: questionCustomElement.getDataFromElement(element),
      })
    }
  })

  return result
}

/**
 * Determine the question type of a question element
 * @param questionElement - The question element to determine the type of
 */
const getQuestionType = (questionElement: HTMLElement): QuestionType => {
  const component = questionElement.dataset.component as
    | "multiple-choice-question"
    | "true-false-question"
    | "fill-in-the-blank-question"
    | "matching-question"

  switch (component) {
    case "multiple-choice-question":
      /*
       * Special case for scenario-based questions. If a multiple choice question has more than 1 sentence,
       * consider it scenario-based and sort it into that category. This only affects filtering, they are
       * otherwise treated the same as normal multiple choice questions.
       */
      return questionElement.innerText.includes(". ")
        ? QuestionType.SCENARIO_BASED_MULTIPLE_CHOICE
        : QuestionType.MULTIPLE_CHOICE
    case "true-false-question":
      return QuestionType.TRUE_FALSE
    case "fill-in-the-blank-question":
      return QuestionType.FILL_IN_BLANK
    case "matching-question":
      return QuestionType.MATCHING
    default:
      throw new Error(`Unhandled question type: ${component}`)
  }
}

/**
 * Filter the questions based on the selected filters and selection index
 * @param questions - Object containing all questions, separated by type
 * @param filters - Array of strings containing selected question type filters
 * @param selectionIndex - The "page" that is currently being viewed for the questions.
 */
const filterQuestions = (
  questions: QuestionsByType,
  filters: QuestionType[],
  selectionIndex: number
): { filteredQuestions: QuestionsByType; hasNextPage: boolean } => {
  let result = {} as QuestionsByType

  if (isEmpty(questions)) {
    return { filteredQuestions: {}, hasNextPage: false }
  }

  const questionsPerPage = 2 // Show 2 questions per type per page

  // First check if there are any questions for the next page
  const hasNextPage = filters.some((questionType) => {
    const questionSubset = questions[questionType]
    if (!questionSubset) return false
    const nextPageStart = (selectionIndex + 1) * questionsPerPage
    return nextPageStart < questionSubset.length
  })

  // Get current page questions
  filters.forEach((questionType) => {
    const questionSubset = questions[questionType]

    if (!questionSubset) {
      return
    }

    const startIndex = selectionIndex * questionsPerPage
    result[questionType] = slice(
      questionSubset,
      startIndex,
      startIndex + questionsPerPage
    )
  })

  return { filteredQuestions: result, hasNextPage }
}

/**
 * Props for QuestionTypeButton
 */
interface QuestionTypeButtonProps {
  type: {
    id: string
    label: string
    icon: React.ReactNode
  }
  isSelected: boolean
  onClick: () => void
  disabled: boolean
}

/**
 * Button for selecting a question type
 * @param props See below.
 * @param props.type - The question type to display
 * @param props.isSelected - Whether the question type is selected
 * @param props.onClick - Callback to handle clicking the question type button
 * @param props.disabled - Whether the question type button is disabled
 */
function QuestionTypeButton(props: QuestionTypeButtonProps) {
  const { type, isSelected, onClick, disabled } = props

  return (
    <Box
      role="button"
      tabIndex={disabled ? -1 : 0}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault()
          if (!disabled) {
            onClick()
          }
        }
      }}
      onClick={disabled ? undefined : onClick}
      sx={{
        // eslint-disable-next-line
        border: (theme) =>
          `1px solid ${
            isSelected ? theme.palette.primary.main : theme.palette.grey[200]
          }`,
        borderRadius: 1,
        p: 2,
        cursor: disabled ? "not-allowed" : "pointer",
        // eslint-disable-next-line
        bgcolor: (theme) =>
          isSelected ? theme.palette.grey[50] : theme.palette.background.paper,
        color: disabled ? "text.disabled" : "text.primary",
        minWidth: 130,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 1,
        transition: "all 0.2s",
        // eslint-disable-next-line
        boxShadow: (theme) =>
          isSelected ? `0 0 0 1px ${theme.palette.primary.main}` : "none",
        "&:hover": {
          // eslint-disable-next-line
          bgcolor: (theme) =>
            disabled
              ? isSelected
                ? theme.palette.grey[50]
                : theme.palette.background.paper
              : isSelected
              ? theme.palette.grey[100]
              : theme.palette.grey[50],
          // eslint-disable-next-line
          borderColor: (theme) =>
            disabled ? theme.palette.grey[200] : theme.palette.primary.main,
        },
        "& .MuiSvgIcon-root": {
          // eslint-disable-next-line
          color: (theme) =>
            disabled
              ? theme.palette.text.disabled
              : isSelected
              ? theme.palette.text.secondary
              : theme.palette.text.disabled,
        },
      }}
    >
      {type.icon}
      <Typography
        variant="body2"
        color={
          disabled
            ? "text.disabled"
            : isSelected
            ? "text.secondary"
            : "text.disabled"
        }
        sx={{ fontWeight: isSelected ? 500 : 400 }}
      >
        {type.label}
      </Typography>
    </Box>
  )
}

export default TestQuestionPanel2

import { useCallback, useRef } from "react"
import { useGoogleLogin } from "@react-oauth/google"
import { gapi } from "gapi-script"
import { useGapi } from "../../../contexts/GapiProvider"
import config from "../../../config"
import { isAccessTokenExpired } from "../../../utilities/gapiUtils"
import { ExportLocation, GoogleWorkspaceType } from "../ExportTypes"

interface UseGoogleDriveUploadPickerProps {
  onGoogleFolderSelected: (location: ExportLocation) => void
  setGoogleDriveAccessPromptDialogOpen: (open: boolean) => void
}

/**
 * Upload location picker for save to Gdrive
 * @param root0 - the params
 * @param root0.onGoogleFolderSelected - called when folder selected
 * @param root0.setGoogleDriveAccessPromptDialogOpen - used to prompt for google access
 */
const useGoogleDriveUploadPicker = ({
  onGoogleFolderSelected,
  setGoogleDriveAccessPromptDialogOpen,
}: UseGoogleDriveUploadPickerProps) => {
  const { isGapiReady } = useGapi()

  const { oauthToken, setOauthToken } = useGapi()
  const pendingSaveToRef = useRef<GoogleWorkspaceType | null>(null) // Add ref

  const scopes = ["https://www.googleapis.com/auth/drive.file"]

  const googleLogin = useGoogleLogin({
    scope: scopes.join(" "),

    overrideScope: true,
    prompt: "consent",
    // eslint-disable-next-line jsdoc/require-jsdoc
    onSuccess: async (tokenResponse) => {
      setOauthToken(tokenResponse)
      doOpenDrivePicker(tokenResponse.access_token, pendingSaveToRef.current!)
    },
    // eslint-disable-next-line jsdoc/require-jsdoc
    onError: (errorResponse) => console.error(errorResponse),
  })

  const doOpenDrivePicker = useCallback(
    (access_token: string, saveTo: GoogleWorkspaceType) => {
      if (!isGapiReady) {
        setGoogleDriveAccessPromptDialogOpen(true)
        return
      }

      const pickerApi = (gapi as any).picker.api
      const viewId = pickerApi.ViewId.FOLDERS

      const view = new pickerApi.DocsView(pickerApi.ViewId[viewId])
        .setIncludeFolders(true)
        .setSelectFolderEnabled(true)
        .setMimeTypes("application/vnd.google-apps.folder")
        .setMode("FOLDER_ONLY")
      try {
        const picker = new pickerApi.PickerBuilder()
          .setAppId(config.googleApiCredentials.clientId)
          .setOAuthToken(access_token)
          .setDeveloperKey(config.googleApiCredentials.apiKey)
          .setTitle("Select Google Drive Folder")
          .addView(view)
          .enableFeature(pickerApi.Feature.SUPPORT_FOLDERS)
          .enableFeature(pickerApi.Feature.MINE_ONLY)
          .setCallback((data: any) => {
            if (data[pickerApi.Response.ACTION] === pickerApi.Action.PICKED) {
              const doc = data[pickerApi.Response.DOCUMENTS][0]
              onGoogleFolderSelected({
                folderId: doc.id,
                folderName: doc.name,
                saveTo,
              })
            }
          })

        // See https://stackoverflow.com/questions/78208244/google-picker-in-electron-returns-403-when-packaged-but-not-local-dev
        picker.vc.set(
          "parent",
          window.location.protocol +
            "//" +
            window.location.host +
            "/favicon.ico"
        )

        picker.build().setVisible(true)
      } catch (error) {
        console.error("Picker creation failed:", error)
      }
    },
    [isGapiReady, onGoogleFolderSelected, setGoogleDriveAccessPromptDialogOpen]
  )

  const openDrivePicker = useCallback(
    async (saveTo: GoogleWorkspaceType) => {
      pendingSaveToRef.current = saveTo

      const prompt_for_access =
        oauthToken == null ||
        (await isAccessTokenExpired(oauthToken.access_token))

      if (prompt_for_access) {
        setGoogleDriveAccessPromptDialogOpen(true)
      } else {
        doOpenDrivePicker(oauthToken.access_token, saveTo)
      }
    },
    [doOpenDrivePicker, oauthToken, setGoogleDriveAccessPromptDialogOpen]
  )

  const onAccessPermitted = useCallback(() => {
    googleLogin()
  }, [googleLogin])

  return {
    openDrivePicker,
    onAccessPermitted,
  }
}

export default useGoogleDriveUploadPicker

import loadFontFace from "./loadFontFace"

/**
 * Merge branding overrides that were already fetched from loading the course
 * with the global branding configuration for a user, and return a branding object
 * that can be injected into the editor
 *
 * @param data - Branding data obtained from loading course from server
 */
const initializeEditorBranding = async (data: any) => {
  const {
    colour,
    main_colour: mainColor,
    background_colour: backgroundColor,
    size = "normal",
    styles = [],
    heading_url,
    heading_name,
    body_url,
    body_name,
    editor_fonts,
  } = data

  const branding = {
    colour,
    mainColor,
    backgroundColor,
    size,
    styles,
    heading: {
      name: heading_name,
      url: heading_url,
    },
    body: {
      name: body_name,
      url: body_url,
    },
    editorFonts: editor_fonts ?? [],
  }

  const fonts = [...branding.editorFonts, branding.heading, branding.body]

  await Promise.all(
    fonts.map(async (font) => {
      await loadFontFace(font)
    })
  )

  return branding
}

export default initializeEditorBranding

import { useEffect, useRef } from "react"
import { useStore } from "react-redux"
import selectUploadJobs, {
  selectCompleteUploads,
} from "../store/jobs/selectUploadJobs"

/**
 * Hook to listen for upload job changes and to invoke a callback
 * when one is completed
 * @param callback - Callback to invoke upon upload completion
 */
const useUploadCompletions = (callback) => {
  const prevPendingUploads = useRef(null)

  const callbackRef = useRef()
  const store = useStore()

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    const { getState } = store

    // Every time redux updates...
    const unsubscribe = store.subscribe(() => {
      if (!callbackRef.current) {
        return
      }

      const pendingUploads = selectUploadJobs(getState())

      // If this is the first store change, set the previous pending uploads and return
      if (!prevPendingUploads.current) {
        prevPendingUploads.current = pendingUploads
        return
      }

      // If there are no active pending uploads and no pending uploads from the previous store change, no action needs to be taken
      if (
        prevPendingUploads.current.length === 0 &&
        pendingUploads.length === 0
      ) {
        return
      }

      const completeUploads = selectCompleteUploads(getState())

      // Identify jobs that have switched from pending to complete since the last store change
      const newUploads = completeUploads.filter((upload) =>
        prevPendingUploads.current.some(
          (job) => job.async_id === upload.async_id
        )
      )

      if (newUploads.length > 0) {
        callbackRef.current(newUploads)
      }

      // Update the previous pending uploads for the next store change
      prevPendingUploads.current = pendingUploads
    })

    return unsubscribe
  }, [store])
}

export default useUploadCompletions

import { useEffect, useState } from "react"
import { checkLms } from "../../../api"
import { LmsTarget, SCORM_LMS_TARGETS } from "../utils/contants"

/**
 * Provides a list of available lms targets.
 */
const useLmsOptions = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [lmsAvailability, setLmsAvailability] = useState<
    Record<string, boolean>
  >({})

  useEffect(() => {
    /**
     * Ask the server which LMS's are active for the user
     */
    const fetchLmsData = async () => {
      try {
        const availabilityChecks = SCORM_LMS_TARGETS.map((target) =>
          checkLms(target.value).then((isAvailable) => ({
            [target.value]: isAvailable,
          }))
        )

        const results = await Promise.all(availabilityChecks)
        const availability = Object.assign({}, ...results)

        setLmsAvailability(availability)
        setLoading(false)
      } catch (error: any) {
        console.error(error)
        setLoading(false)
        setError(error)
      }
    }

    fetchLmsData()
  }, [])

  /**
   * Filter out unavailable lms options
   * @param options - output lms option
   */
  const filterOptions = (options: LmsTarget): boolean => {
    return lmsAvailability[options.value] ?? true
  }

  const lmsTargets: ReadonlyArray<LmsTarget> =
    SCORM_LMS_TARGETS.filter(filterOptions)

  return { lmsTargets, loading, error }
}

export default useLmsOptions

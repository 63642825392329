import { Stack } from "@mui/material"
import useMicrolearning from "../../../microlearning/hooks/useMicrolearning"
import PageLayout from "../../layouts/PageLayout"
import MicrolearningForm from "./components/MicrolearningForm"
import Breadcrumbs from "../../../../components/atoms/Breadcrumbs"
import MicrolearningPreview from "./components/MicrolearningPreview"
import { CustomerThemeProvider } from "../../../../contexts/customerTheme"

/**
 * Component for rendering the microlearning page
 * @param props - Component props
 */
const MicrolearningPage = (props: any) => {
  const { location } = props
  const course = location.state?.course ?? null

  const microlearning = useMicrolearning()
  const courseID = course?.id

  /**
   * Include course breadcrumb if we have it in state
   */
  const breadcrumbs = [
    { label: "Courses", href: "/" },
    ...(course
      ? [
          {
            label: `"${course.title}"`,
            href: `/editor?course=${courseID}`,
          },
        ]
      : []),
    { label: "Microlearning" },
  ]

  return (
    <CustomerThemeProvider courseID={courseID} useNewUX>
      <PageLayout location={location}>
        <Stack height="100%" width="100%">
          {/*@ts-ignore*/}
          <Breadcrumbs breadcrumbs={breadcrumbs} sx={{ marginBottom: 2 }} />
          <Stack
            flex="1 1 0"
            sx={{ overflow: "hidden" }}
            direction="row"
            gap={2}
            width="100%"
          >
            <MicrolearningForm course={course} {...microlearning} />
            <MicrolearningPreview {...microlearning} />
          </Stack>
        </Stack>
      </PageLayout>
    </CustomerThemeProvider>
  )
}

export default MicrolearningPage

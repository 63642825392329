import { Backdrop, Box, CircularProgress, Typography } from "@mui/material"
import React from "react"

/**
 * Component for rendering overlay when Micro-Learning is building
 * @param props - Component props
 */
const LoadingMicroLearningBackdrop = (props: any) => {
  const { open } = props

  return (
    <Backdrop
      sx={{
        /**
         * Set colour of text to be themed white
         * @param theme - MUI theme
         */
        // @ts-ignore
        color: (theme) => theme.palette.lexWhite,
        /**
         * Set backdrop to be above rest of content
         * @param theme - MUI theme
         */
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      <Box
        data-cy="Microlearning-export-loading"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography gutterBottom variant="h6">
          Generating Microlearning
        </Typography>
        <Typography gutterBottom variant="body2">
          Our AI is analyzing your text; this may take several minutes to
          complete...
        </Typography>
        <CircularProgress sx={{ marginTop: 2 }} color="inherit" />
      </Box>
    </Backdrop>
  )
}

export default LoadingMicroLearningBackdrop

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  useMediaQuery,
} from "@mui/material"
import { forwardRef } from "react"
import { useTheme, Theme } from "@mui/material/styles"
import { GoogleDriveUploadResult } from "../../features/export/api/uploadToGoogleDrive"
import { dialogConfig } from "../../config/dialogConfig"

interface GoogleUploadDialogProps {
  courseTitle: string
  result: GoogleDriveUploadResult | null
  onClose: () => void
  onOpen?: () => void
}
/**
 * A modal presenting the scorm URL for a course exported to Google drive
 *
 * @param props The props
 * @param props.courseTitle Title of course
 * @param props.result  - result of upload
 * @param props.onClose - callback on close
 * @param props.onOpen - callback on open

 */
const GoogleUploadDialog = ({
  courseTitle,
  result,
  onClose,
  onOpen,
}: GoogleUploadDialogProps) => {
  const open = result != null
  const fullScreen = useFullscreenDialog()
  const webViewLink = result ? result.webViewLink : undefined
  const folderLink = result ? result.folderLink : undefined

  return (
    <Dialog
      {...dialogConfig}
      fullScreen={fullScreen}
      scroll={fullScreen ? "paper" : "body"}
      open={open}
      onClose={onClose}
      container={document.body}
      TransitionProps={{
        // eslint-disable-next-line jsdoc/require-jsdoc
        onEntered: () => onOpen?.(),
      }}
      sx={{
        ".MuiDialog-paperFullScreen": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle>Exported to Google Drive</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 1 }}>
          Course "{courseTitle}" has been exported to "{result?.fileName}" in
          folder "{result?.folderName}."
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "right" }}>
        {webViewLink && (
          <Button
            href={webViewLink}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.preventDefault()
              window.open(webViewLink, "_blank", "noopener,noreferrer")
            }}
          >
            View File
          </Button>
        )}
        {folderLink && !webViewLink && (
          <Button
            href={folderLink}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.preventDefault()
              window.open(folderLink, "_blank", "noopener,noreferrer")
            }}
          >
            View Folder
          </Button>
        )}
        <Button variant="contained" onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

GoogleUploadDialog.defaultProps = {
  open: false,
}

export default GoogleUploadDialog

/**
 * Show as full-screen on sm displays
 */
const useFullscreenDialog = () => {
  const theme: Theme = useTheme()
  return useMediaQuery(theme.breakpoints.down("sm"))
}

const Transition = forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

import React, { ReactNode, useEffect, useState } from "react"
import Dialog from "@mui/material/Dialog"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import * as api from "../../api"
import { Box, LinearProgress } from "@mui/material"
import { exportAsyncStatusEnum } from "./utils/contants"
import ErrorMessage from "../../components/widgets/ErrorMessage"

// Defines how long to wait between async job checks
const pollDelayTime = 1000

/**
 * The preview process can take time, and uses an Async Job Service
 * on the server to prepare the preview.
 *
 * The following objects define states and user messages for each of
 * the async job steps.
 */
export const asyncStatusMessage = {
  start: "Starting preview process",
  processing: "Processing preview",
  fetch_course: "Loading course details.",
  create_export_assets:
    "Building preview files. This may take several minutes to complete...",
  publish_url: "Preparing preview",
  complete: "",
  failed:
    "Preview of course failed. Systems may be busy. Please try again later.",
} as any

export interface PreviewScreenProps {
  /** course id to preview */
  courseId: string
  useServerlessExport: boolean
  isScorm: boolean
  /** Asynchronously get the course description with the preview flag set. */
  getPreviewCourseDescription: () => Promise<any>
  /** Call when the dialog box is to close. */
  onClose: () => void
  /** Creates the preview content given the URL of the preview */
  createPreviewContent: (previewUrl: string) => ReactNode
  /** Export buttons to render in the toolbar */
  exportButton: ReactNode
}

/** Displays a full-screen modal interactive preview
 *
 * @param props for screen
 */
export function PreviewScreen(props: PreviewScreenProps) {
  const {
    courseId,
    getPreviewCourseDescription,
    onClose,
    useServerlessExport,
    isScorm,
    exportButton,
    createPreviewContent,
  } = props

  const [status, setStatus] = useState<string>("Requesting preview")
  const [error, setError] = useState<string>()
  const [previewUrl, setPreviewUrl] = useState<string>()

  useEffect(() => {
    /** Start the preview by generating SCORM */
    const startPreviewProcess = async () => {
      try {
        const courseDescription = await getPreviewCourseDescription()
        let asyncJobId = null

        if (useServerlessExport) {
          const { data } = await api.exportCourseAssets(
            courseId,
            courseDescription
          )
          const resp = await api.startExport(data)
          console.log("JOB ID ----->", resp.data.job_id.split("_"))
          asyncJobId = resp.data.job_id
        } else {
          const { data } = await api.exportCourseAssets(
            courseId,
            courseDescription
          )
          asyncJobId = data.async_id
        }

        await checkJobStatus(asyncJobId)
      } catch (error: any) {
        console.error("startPreviewProcess", error)
        setError(error.message)
      }
    }

    /** @param jobId - check the SCORM export process*/
    const checkJobStatus = async (jobId: string) => {
      try {
        let jobStatusData
        if (useServerlessExport) {
          jobStatusData = await api.fetchExportStatus(jobId)
        } else {
          const { data } = await api.checkAsyncJob(jobId)
          jobStatusData = data
        }

        if (
          jobStatusData == null ||
          jobStatusData.status === exportAsyncStatusEnum.FAILED_NO_RETRY
        ) {
          setError(asyncStatusMessage[exportAsyncStatusEnum.FAILED_NO_RETRY])
        } else if (jobStatusData.status === exportAsyncStatusEnum.COMPLETE) {
          let previewExportUrl
          if (useServerlessExport) {
            previewExportUrl = jobStatusData.export_url
            if (isScorm) {
              previewExportUrl = await api.createExportPreview(
                courseId,
                previewExportUrl
              )
            }
          } else {
            previewExportUrl = jobStatusData.data.export_url
          }

          setPreviewUrl(previewExportUrl)
        } else {
          setStatus(asyncStatusMessage[jobStatusData.status])
          setTimeout(() => checkJobStatus(jobId), pollDelayTime)
        }
      } catch (error: any) {
        console.error("checkJobStatus", error)
        setError(error.message)
      }
    }

    startPreviewProcess()
  }, [courseId, getPreviewCourseDescription, isScorm, useServerlessExport])

  return (
    <Dialog fullScreen open={true} onClose={onClose}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Preview</Typography>
          <Box sx={{ marginLeft: "auto" }}>{exportButton}</Box>
        </Toolbar>
      </AppBar>
      {!previewUrl && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          {error ? (
            <>
              <ErrorMessage
                title="Oh no!"
                subtitle="A problem occurred while previewing your course."
              >
                <Typography variant="body2">
                  Your course could not be previewed. Please try again, contact
                  LearnExperts if the problem persists
                </Typography>
              </ErrorMessage>

              <Box textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CloseIcon />}
                  onClick={(e) => {
                    e.preventDefault()
                    onClose()
                  }}
                >
                  Close
                </Button>
              </Box>
            </>
          ) : (
            <Box>
              <Typography variant="h6" align="center">
                {status}
              </Typography>
              <LinearProgress variant="indeterminate" />
            </Box>
          )}
        </Box>
      )}
      {previewUrl && createPreviewContent(previewUrl)}
    </Dialog>
  )
}

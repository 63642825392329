import { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import mixpanel from "mixpanel-browser"
import { useAuth } from "../contexts/authorization"
import { UploadItem } from "./fileUpload"
import { useCallback } from "react"

const navEvents: { [key: string]: string } = {
  "/": "Home View",
  "/create-course": "Create Course View",
  "/creating-course": "Creating Course View",
  "/intelligent-update": "Intelligent Update View",
  "/microlearning": "Microlearning View",
  "/editor": "Editor View",
  "/editor/": "Editor View",
  "/tools": "Tools View",
  "/help": "Help View",
  "/export": "Export View",
  "/history": "History View",
  "/find": "Find and Replace View",
  "/share": "Share Course View",
  "/give": "Give Course View",
  "/trash": "Trash View",
  "/branding": "Branding View",
  "/translation-rules": "Translation View",
  "/login": "Login View",
}

export enum ButtonEvents {
  CreateCourse = "Create Course Clicked",
  TransformAction = "Transform Action Clicked",
  ReviewedAction = "Reviewed Action Clicked",
  PreviewCourse = "Preview Course Clicked",
  ExportCourse = "Export Course Clicked",
  MicrolearningExportCourse = "Microlearning Export Course Clicked",
  MicrolearningPreviewCourse = "Microlearning Preview Course Clicked",
  ApplyIntelligentUpdate = "Apply Intelligent Update Clicked",
  IgnoreIntelligentUpdate = "Ignore Intelligent Update Clicked",
}

export enum SystemEvents {
  CreateCourseStart = "Create Course Start",
}

interface Course {
  id: string
  tenantID: string
  title: string
}

/**
 * Function to extract the domain from an email.
 * @param email - users email
 */
function getDomainFromEmail(email: string | undefined): string | null {
  if (!email) return null
  const parts = email.split("@")
  return parts.length === 2 ? parts[1] : null
}

/**
 * Tracks page views using Mixpanel whenever the user navigates to a new page.
 */
export function useMixpanelNavigationTracker(): void {
  const history = useHistory()
  const location = useLocation()
  const { user }: any = useAuth()

  useEffect(() => {
    const domain = getDomainFromEmail(user?.email)
    let pageEvent = navEvents[location.pathname] || "Page View"

    mixpanel.track(pageEvent, {
      page: location.pathname,
      search: location.search,
      userID: user?.id || "Anonymous",
      $username: user?.name || "Anonymous",
      $email: user?.email || "Unknown",
      domain: domain || "Unknown",
    })

    const unlisten = history.listen((newLocation) => {
      let newPageEvent = navEvents[newLocation.pathname] || "Page View"
      mixpanel.track(newPageEvent, {
        page: newLocation.pathname,
        search: newLocation.search,
        $username: user?.name || "Anonymous",
        $email: user?.email || "Unknown",
        domain: domain || "Unknown",
      })
    })

    return () => {
      unlisten()
    }
  }, [history, location, user])
}

/**
 * Tracks a button click using Mixpanel.
 *
 * @param {string} buttonName - The name of the button being tracked.
 * @returns - A function that tracks the button click event.
 */
export function useMixpanelButtonTracker(buttonName: string) {
  const { user }: any = useAuth()
  const domain = getDomainFromEmail(user?.email)

  /**
   * tracks button click
   */
  const trackButtonClick = () => {
    mixpanel.track(buttonName, {
      userID: user?.id || "Anonymous",
      $username: user?.name || "Anonymous",
      $email: user?.email || "Unknown",
      domain: domain || "Unknown",
    })
  }

  return trackButtonClick
}

/**
 * Tracks a button click with associated course data using Mixpanel.
 *
 * @param {string} buttonName - The name of the button being tracked.
 * @param {Course} course - The course object containing course metadata.
 * @returns {() => void} - A function that tracks the button click event along with course metadata.
 */
export function useMixpanelCourseTracker(
  buttonName: string,
  course: Course
): () => void {
  const { user }: any = useAuth()
  const domain = getDomainFromEmail(user?.email)

  /**
   * tracks button click
   */
  const trackButtonClick = () => {
    mixpanel.track(buttonName, {
      button: buttonName,
      courseID: course.id,
      tenantID: course.tenantID,
      courseTitle: course.title,
      $username: user?.name || "Anonymous",
      $email: user?.email || "Unknown",
      domain: domain || "Unknown",
    })
  }

  return trackButtonClick
}

interface UploadOptions {
  applyTrainingStructure: boolean
  learningPath: string
  directory: string
}

/**
 * Tracks the number of files uploaded using Mixpanel.
 * @returns {() => void} A function that tracks the file upload event.
 */
export function useMixpanelFileUploadTracker() {
  const { user }: any = useAuth()
  const domain = getDomainFromEmail(user?.email)
  /**
   * Tracks number of files uploaded using Mixpanel
   * @param uploadItemList - list of items being uploaded
   * @param options - UploadOptions
   */
  const trackFileUpload = (
    uploadItemList: UploadItem[],
    options: UploadOptions
  ) => {
    if (uploadItemList.length <= 0) return // No files to track

    mixpanel.track("File Upload", {
      numberOfFiles: uploadItemList.length,
      userID: user?.id || "Anonymous",
      $username: user?.name || "Anonymous",
      $email: user?.email || "Unknown",
      domain: domain || "Unknown",
    })

    // Track detailed file upload information
    mixpanel.track("File Upload Details", {
      fileCount: uploadItemList.length,
      fileTypes: uploadItemList.map((item) => item.type),
      options,
      files: uploadItemList.map((item) => ({
        name: item.displayName,
        type: item.type,
        source: item.source,
        transcribeMode: item.transcribeMode,
        rewriteMode: item.rewriteMode,
        webFilterMode: item.webFilterMode,
      })),
    })
  }

  return trackFileUpload
}

/**
 * Custom hook to track file data imported by user
 * * @returns {{trackEvent: function}} - An object containing the `trackEvent` function
 */
export function useMixpanelSystemEvent() {
  const { user }: any = useAuth()
  const domain = getDomainFromEmail(user?.email)
  /**
   * Sends event details and optional data to Mixpanel, including the user ID
   * @param eventName - name of the system event to track
   * @param data - Additional data to include with the event
   * @returns {void}
   */
  const trackEvent = (eventName: SystemEvents, data = {}) => {
    mixpanel.track(eventName, {
      ...data,
      userID: user?.id || "Anonymous",
      $username: user?.name || "Anonymous",
      $email: user?.email || "Unknown",
      domain: domain || "Unknown",
    })
  }
  return { trackEvent }
}

/**
 * Custom hook to track Audio insert by user
 * * @returns {{trackAudio: function}} - An object containing the `trackAudio` function
 */
export function useMixpanelAudioTracker() {
  const { user }: any = useAuth()
  const domain = getDomainFromEmail(user?.email)

  /**
   * sends event details upon Audio insert
   * @param title - inserted Audio title
   * @param url - Audio url
   * @param courseId - course ID for course which Audio was inserted in
   */
  const trackAudio = (title: string, url: string, courseId: string) => {
    const data = {
      title: title || "No Title",
      url: url || "No URL",
      courseId: courseId || "No Course",
      userID: user?.id || "Anonymous",
      $username: user?.name || "Anonymous",
      $email: user?.email || "Unknown",
      domain: domain || "Unknown",
    }
    mixpanel.track("Audio Insert", data)
  }

  return trackAudio
}

/**
 * Custom hook to track Video insert by user
 * @returns {{trackVideo: function}} - An object containing the `trackVideo` function
 */
export function useMixpanelVideoTracker() {
  const { user }: any = useAuth()
  const domain = getDomainFromEmail(user?.email)

  /**
   * Sends event details upon Video insert
   * @param url - Video url
   * @param courseId - course ID for course which Video was inserted in
   */
  const trackVideo = (url: string, courseId: string) => {
    const data = {
      url: url || "No URL",
      courseId: courseId || "No Course",
      userID: user?.id || "Anonymous",
      $username: user?.name || "Anonymous",
      $email: user?.email || "Unknown",
      domain: domain || "Unknown",
    }
    mixpanel.track("Video Insert", data)
  }

  return trackVideo
}

/**
 * Tracks Froala toolbar button clicks using Mixpanel.
 * @returns A function that tracks the toolbar button click event.
 */
export function useMixpanelFroalaToolbarTracker() {
  const { user }: any = useAuth()
  const domain = getDomainFromEmail(user?.email)

  /**
   * Tracks a toolbar button click
   * @param buttonName - The name of the button that was clicked
   */
  const trackToolbarClick = useCallback(
    (buttonName: string) => {
      try {
        mixpanel.track("Froala Toolbar Click", {
          button: buttonName,
          userID: user?.id || "Anonymous",
          $username: user?.name || "Anonymous",
          $email: user?.email || "Unknown",
          domain: domain || "Unknown",
        })
      } catch (error) {
        // Log error but don't affect editor functionality
        console.error("Failed to track Froala toolbar click:", error)
      }
    },
    [user, domain]
  )

  return trackToolbarClick
}

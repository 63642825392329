import { createInsertImageDialog } from "./InsertImageDialog"
import {
  findHTMLElementFromNode,
  selectAfterText,
} from "../../utilities/domUtils"

/**
 * Callback to insert image into HTML when dialog is closed
 * @param anchorNode - Location in editor
 * @param onClose - Callback to close dialog
 * @param editor - Froala editor instance
 */
const onCreateImage = (anchorNode: any, onClose: () => void, editor: any) =>
  createInsertImageDialog((url) => {
    onClose()
    if (url) {
      const anchorElement = findHTMLElementFromNode(anchorNode, editor)
      anchorElement.insertAdjacentHTML("afterend", "<p><br/></p>")

      const lineBreakElement = anchorElement.nextElementSibling
      selectAfterText(lineBreakElement, editor)

      editor.image.insert(url, true, { link: url }, null)
      const firstChild = lineBreakElement.firstChild
      if (firstChild instanceof HTMLElement && firstChild.tagName === "BR") {
        lineBreakElement.removeChild(firstChild)
      }
    }
  })

export default onCreateImage

import { Container, Stack } from "@mui/material"
import NavigationRail from "../navigationRail/NavigationRail"

/**
 * Component for rendering basic layout for UX
 * @param props - Component props
 */
const PageLayout = (props: any) => {
  const { navRail, children, location } = props
  return (
    <Stack
      paddingY={2}
      direction="row"
      sx={(theme) => ({
        backgroundColor: theme.scheme.surfaceContainer,
        minHeight: "100vh",
        height: "100vh",
      })}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          ...(navRail && { paddingLeft: "0 !important" }),
          flex: "1 0 0", // Apply 0 flex basis to account for width pushed from nav rail
        }}
      >
        {navRail && <NavigationRail location={location} />}
        {children}
      </Container>
    </Stack>
  )
}

export default PageLayout

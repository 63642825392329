import {
  findHTMLElementFromNode,
  selectAfterText,
} from "../../utilities/domUtils"
import { createInsertVideoDialog } from "./InsertVideoDialog"
import createIFrame from "./utils/createIFrame"

/**
 * Callback to insert video into HTML when dialog is closed
 * @param anchorNode - Location in editor
 * @param onClose - Callback to close dialog
 * @param editor - Editor instance
 */
const onCreateVideo = (anchorNode: any, onClose: () => void, editor: any) =>
  createInsertVideoDialog((url) => {
    onClose()
    if (url) {
      const videoIFrame = createIFrame(url)
      const anchorElement = findHTMLElementFromNode(anchorNode, editor)

      // Reselect the text to keep cursor in place
      selectAfterText(anchorElement, editor)
      anchorElement.insertAdjacentHTML("afterend", videoIFrame)
      editor.undo.saveStep()

      const firstChild = anchorElement.firstChild

      // Remove the initial linebreak once the video is inserted
      if (firstChild && firstChild.tagName === "BR") {
        anchorElement.removeChild(firstChild)
      }
    }
  })

export default onCreateVideo
